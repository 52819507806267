<template>
  <div class="mt-5 mb-5 ml-auto mr-auto" :key="componentKey">
    <div class="container">
      <div class="row no-gutters w-100 mt-2">
        <div class="col-lg-4">
          <div class="">


            <div class="w3-card-2 w3-center custom-profile-card">
              <div class="pt-4 pb-4">

                <div>


                  <div class="custom-profile-image-wrap" v-if="userImage != null">
                    <span class="b-avatar badge-info rounded-circle custom-b-avatar-profile">
                      <span class="b-avatar-img">
                        <span v-if="userImage != null && userImage != ''">
                          <img :src="url + userImage">
                        </span>
                        <span v-else>

                          <img src="../../assets/images/no-image.png">


                        </span>

                      </span>

                    </span>
                    <!--                      <img :src="url+userImage" alt="" class="rounded-circle custom-profile-image">-->
                  </div>
                  <!--                    <div class="custom-profile-image-wrap" v-else>
                      <div >
                        <img src="../../assets/images/no-image.png" alt="" class="w3-circle custom-profile-image">
                      </div>


                      &lt;!&ndash;                  <img src="../../assets/images/avatar-2.jpg" alt="" class="w3-circle custom-profile-image">&ndash;&gt;

                    </div>-->


                </div>
                <div class=" w3-center custom-profile-detail" style="color:black;">

                  <h5 class="font-weight-bold text-capitalize">

                    {{ fullName }}

                  </h5>

                  <div class="text-capitalize">
                    {{ latestExperience.jobTitle }}
                  </div>
                  <!--                  <section class="cd-intro">
                    <h5 class="cd-headline loading-bar">

                    <span class="cd-words-wrapper">
&lt;!&ndash;                      <b class="is-visible">.Net Developer</b>&ndash;&gt;
                      <b class="is-visible" v-for="exp in employeeExperience" :key="exp.empExperienceId">
                        {{ exp.jobTitle }}
                      </b>

                    </span>
                    </h5>

                  </section>-->
                  <div class="custom-profile-contacts">
                    <div class="">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="phoneNumberLink + mobileNumber">
                        <span><i class="fas fa-phone-alt fa-md fa-fw mr-2"></i></span>
                        <!--                        <span>{{ code }}</span>
                        <span>-</span>
                        <span>{{ networkCode }}</span>
                        <span>-</span>-->
                        <span class="">{{ mobileNumber }}</span>
                      </a>


                    </div>

                    <div class=" ">
                      <a class="text-decoration-none w3-hover-text-light-gray" :href="emailLink + email">
                        <i class="fas fa-envelope fa-md fa-fw mr-2"></i>
                        <span style="overflow-wrap: break-word;">{{ email }}</span>
                      </a>
                    </div>
                  </div>
                  <div class="custom-row justify-content-center mt-2 ">
                    <a :href="'https://' + facebookLink" type="url" target="_blank"
                      class="custom-facebook-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-facebook-f"></i>
                    </a>

                    <a :href="'https://' + googlePlusLink" type="url" target="_blank"
                      class="custom-googleplus-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-google"></i>
                    </a>
                    <a :href="'https://' + linkedInLink" type="url" target="_blank"
                      class="custom-linkedin-icon custom-icon-style mr-2 custom-social-link">
                      <i class="fab fa-linkedin-in"></i>
                    </a>
                    <a :href="'https://' + twitterLink" type="url" target="_blank"
                      class="custom-twitter-icon custom-icon-style custom-social-link">
                      <i class="fab fa-twitter"></i>
                    </a>

                  </div>
                </div>
                <div style="display: flex; justify-content: center;">
                  <ProfileStrength :profileStrengthSection="true" @get-strength="recievedStrength"
                    style="display: flex; justify-content: center;"></ProfileStrength>
                </div>
                <div style="display: flex;
 justify-content: center;
 align-items: center;
 margin-top: 11px;">
                  <router-link to="/employee-profile" class="custom-default-btn"
                    style="float:right;margin-top:0px;"><i>Complete Your CV</i></router-link>

                </div>


              </div>

            </div>
          </div>
        </div>
        <div class="col-lg-8">
          <div class="container">
            <div>
              <span class="custom-profile-heading">Personal Information</span>
              <router-link to="/add-profile-detail" class="custom-default-btn" style="float:right; margin-top:11px;">
                <i>Edit Personal Information</i>
              </router-link>
            </div>

            <div style="width: auto;max-height: 200px;min-height: 146px;">
              <!-- Action Buttons Row -->
              <!-- <div class="action-container"> -->
                <!-- Upload CV Button -->
                
                  <div v-if="uploadCVBtn" style="padding-top: 10px;height: 60px;">
                    <a @click="triggerFileInput('UploadCvInput')" class="update-cv-btn btnStyle custom-default-btn"
                      style="cursor: pointer">
                      <b-icon class="mr-2" icon="upload"></b-icon>Upload CV
                    </a>
                    <input type="file" ref="UploadCvInput" style="display: none;" accept="application/pdf"
                      @change="handleCvUploadFileChange" />
                      <span v-if="selectedCVFileName" class="file-name mt-2 up docsListStyle" style="color: black; margin:4px 0px 4px 2px">
                    1.  {{ selectedCVFileName }}
                    <button @click="removeResume(fullFileName)" style="background: none; border: none; cursor: pointer;">
                          <b-icon class="mr-2" icon="trash"></b-icon>
                        </button>
                      </span>
                  </div>
                 
                  <!-- Update CV Button -->
                  <div v-if="updateCvBtn" style="padding-top: 10px;height: 60px;">
                    <a title="Update CV" @click="triggerFileInput('cvUpadteInput')" data-backdrop="static"
                      data-keyboard="false" class="update-cv-btn btnStyle custom-default-btn" style="cursor: pointer">
                      <b-icon class="mr-1" icon="arrow-clockwise"></b-icon>Update CV
                    </a>
                    <input type="file" ref="cvUpadteInput" style="display: none;" accept="application/pdf"
                      @change="handleUpdateCvFileChange" />
                      <span v-if="selectedCVFileName" class="file-name mt-2 docsListStyle" style="color: black;">
                     1. {{ selectedCVFileName }}
                     <button @click="removeResume(fullFileName)" style="background: none; border: none; cursor: pointer;">
                          <b-icon class="mr-2" icon="trash"></b-icon>
                        </button>
                      </span>
                  </div>

                  <!-- Update CV name display handle condition -->
                  
                  <div v-if="uploadAssessmentBtn" style="padding-top: 10px;height: 60px;">
                    <a @click="triggerFileInput('assessmentInput')" class="upload-Assessment-btn btnStyle custom-default-btn"
                      style="cursor: pointer">
                      <b-icon class="mr-1" icon="upload"></b-icon>Upload Assessment
                    </a>
                    <input type="file" ref="assessmentInput" style="display: none;" accept="application/pdf"
                      @change="handleAssessmentFileChange"/>
                      <span v-if="employeeDocuments.length > 0" class="file-name mt-2" style="color: black;">
                   
                   <!-- Loop through employeeDocuments instead -->
                   <span v-for="(doc, index) in employeeDocuments" :key="index" style="list-style: none;" class="docsListStyle">
                     <span class="file-name mt-2 docsListStyle">{{ index + 1 }}. {{ extractFileName(doc.fileName) }}</span>
                     <input id="uploadedDate" type="hidden" :value="doc.uploadedDate" />
                     <input id="assessmentDocumentId"  type="hidden" :value="doc.assessmentDocumentId" />
                     <button @click="removeFile(doc,index)" style="background: none; border: none; cursor: pointer;">
                       <b-icon class="mr-2" icon="trash"></b-icon>
                     </button>
                    </span>
                
                  </span>
                  </div>
               
                  <!-- Check if there are documents to display -->
                  
                
                <!-- Update Assessment Section -->
               
                  <div v-if="updateAssessmentBtn" style="padding-top: 10px;height: 60px;">
                    <a title="Update Assessment" @click="triggerFileInput('assessmentUpdated')" data-backdrop="static"
                      data-keyboard="false" class="update-assessment-btn btnStyle custom-default-btn" style="cursor: pointer">
                      <b-icon class="mr-1" icon="arrow-clockwise"></b-icon>Update Assessment
                    </a>
                    <!-- Hidden file input for multiple file uploads -->
                    <input type="file" ref="assessmentUpdated" style="display: none;" accept="application/pdf"
                      @change="handleAssessmentUpdateFileChange"/>
                      <span v-if="employeeDocuments.length > 0" class="file-name mt-2" style="color: black;">
                   
                      <!-- Loop through employeeDocuments instead -->
                      <span v-for="(doc, index) in employeeDocuments" :key="index" style="list-style: none;" class="docsListStyle">
                        <span class="file-name mt-2 docsListStyle">{{ index + 1 }}. {{ extractFileName(doc.fileName) }}</span>
                        <input id="uploadedDate" type="hidden" :value="doc.uploadedDate" />
                        <input id="assessmentDocumentId"  type="hidden" :value="doc.assessmentDocumentId" />
                        <button  @click="removeFile(doc, index)" style="background: none; border: none; cursor: pointer;">
                          <b-icon class="mr-2" icon="trash"></b-icon>
                        </button>
                      </span>
                    </span>
                  </div>
                  <button @click="saveDocumnet" class="btnstyle custom-default-btn btnStyle" style="margin: 10px 0px;">Save</button>
                  <!-- Display selected file names for update -->
              <!-- </div> -->
              
            </div>
            <div class="row">
              <div class="col-xl-6">
                <div class="w3-card-2 w-100 h-100">
                  <div class="card-body">
                    <h4 class="card-title mb-4 text-center">My Applied Job Applications</h4>
                    <apexchart width="100%" type="bar" :options="barChartOptions" :series="barChartSeries"></apexchart>
                  </div>
                </div>
              </div>

              <div class="col-xl-6">
                <div class="w3-card-2 w-100 h-100">
                  <div class="card-body">
                    <h4 class="card-title mb-5 text-center">Number of Profile Viewed</h4>
                    <apexchart width="100%" type="line" :options="lineChartOptions" :series="lineChartSeries">
                    </apexchart>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>

      <div class="row no-gutters w-100 mt-4">
        <!-- <div class="col-lg-2 w-100 h-100">
          <div v-if="sMWCoursesAdd.length != 0" class="w-100 h-100 text-center">
            <a :href="sMWCoursesAdd[0].url" :title="sMWCoursesAdd[0].url" target="_blank" class="navbar-brand mr-0">
              <img :src="url+sMWCoursesAdd[0].image" class="advertisementimage" />
            </a>
          </div>
          <div v-else class="w-100 h-100 text-center">
            <img src="../../assets/images/hersherhar vertical.jpg" class="advertisementimage"/>
          </div>

        </div> -->
        <div class="col-lg-12">
          <div class="m-4">
            <div v-if="jobsStatuses != null && jobsStatuses.length != 0" class=" pt-3 pr-2">
              <div class="w3-card-2 pt-3 pb-3 ml-auto mr-auto" style="width:100%">
                <header class="w3-container custom-row ">
                  <h3>My Applications</h3>
                  <router-link :to="{ name: 'EmployeeJobs', params: { status: 1 } }"
                    class="btn btn-outline-secondary ml-auto">View All Applications</router-link>
                </header>
                <hr>
                <div style="width:100%;max-height: 400px;overflow: auto">
                  <div class="w3-container " v-for="(job, jobIndex) in jobsStatuses" :key="job.jobApplicantId">


                    <div v-if="job.jobsStatus === 'Applied'" class="mt-2 mb-2 custom-row">

                      <div class="row no-gutters align-items-center flex-grow-1">

                        <div class="col-sm-2">
                          <div class="style-left-img">
                            <span class="b-avatar badge-info rounded-circle custom-b-avatar">
                              <span class="b-avatar-img">
                                <span v-if="job.employeeImage != null && job.employeeImage != ''">
                                  <img :src="url + job.employeeImage">
                                </span>
                                <span v-else>

                                  <img src="../../assets/images/no-image.png">


                                </span>

                              </span>

                            </span>
                          </div>
                        </div>
                        <div class="col-sm-10">

                          <div class="custom-job-title text-dark text-capitalize style-text-14">{{ job.jobTitle }}</div>
                          <div class="style-company custom-muted-text style-text-14">{{ job.companyName }}</div>
                          <div class="style-job-metas">
                            <div class="style-job-salary custom-muted-text style-text-14">
                              <i class="fas fa-money-bill"></i>
                              <span v-if="job.packageTo === 0 || job.packageTo === 1">Market Competitive Salary</span>
                              <span v-else>Rs {{ job.packageFrom }} - {{ job.packageTo }}/Month</span>
                              <!-- Rs {{ job.packageFrom }} - {{ job.packageTo }}/Month -->
                            </div>
                            <div class="location custom-muted-text style-text-14">
                              <i class="text-second fa fa-map-marker"></i>
                              <span>{{ job.cityName }}, {{ job.countryName }}</span>
                            </div>
                          </div>
                        </div>

                      </div>




                      <div class="ml-auto">
                        <div v-for="(date, dateIndex) in appliedDate" :key="date">
                          <p v-if="dateIndex == jobIndex" class="font-weight-bold text-black m-0">

                            <span v-if="date === 0">Today</span>
                            <span v-else-if="date === 1">{{ date }} Day Ago</span>
                            <span v-else>{{ date }} Days Ago</span>
                          </p>
                        </div>

                        <!--                      <a href="#">Portfolio</a>-->
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <!--SPACER-->
            <div class="style-spacer"></div>

            <!--ADVERTISEMENT START--->
            <!-- <div class="style-advertisement-portion">
              <div class="">

                <div class="style-category-wrapper-item">
                  <div v-if="employeeDashboardCenterAdd.length != 0" class="w-100 text-center">
                    <a :href="employeeDashboardCenterAdd[0].url" :title="employeeDashboardCenterAdd[0].url" target="_blank" class="navbar-brand mr-0">
                      <img :src="url+employeeDashboardCenterAdd[0].image" class="w-100" />
                    </a>
                  </div>
                  <a v-else href="#">
                    <div class="text-center">Advertisement</div>
                  </a>
                </div>

              </div> 



            </div> -->


            <!--SPACER-->
            <div class="style-spacer"></div>

            <!-- Featured Jobs Start   -->
            <div v-if="featuredJobs != null && featuredJobs.length != 0">
              <div class="recommended-jobs-header">
                <div class="row">
                  <div class="col-6">
                    <div class="style-latest-jobs">
                      <a href="#">
                        <div>
                          <h3>Featured Jobs</h3>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="float-right">
                      <a @click="showPrevious()" class="style-hand-icon">
                        <span>
                          <arrow-left-circle-icon size="1.9x" class="custom-class back-btn"></arrow-left-circle-icon>
                        </span>
                      </a>

                      <a @click="showNext()" class="style-hand-icon">
                        <span>
                          <arrow-right-circle-icon size="1.9x"
                            class="custom-class forward-btn"></arrow-right-circle-icon>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="style-latest-jobs-slides">
                <div class="container">
                  <div class="row no-gutters">
                    <div class="col-12">
                      <div class="style-multirow-slide">
                        <VueSlickCarousel v-bind="settings" ref="carousel_feature_jobs" v-if="featuredJobs.length">
                          <div class="row-slider-item" v-for="jt in featuredJobs" :key="jt.jobId">
                            <div class="card job-card">
                              <div class="row no-gutters align-items-center">
                                <div class="col-sm-3">
                                  <div class="style-left-img">
                                    <span class="b-avatar badge-info rounded-circle custom-b-avatar">
                                      <span class="b-avatar-img">
                                        <span v-if="jt.companyLogoAddress != null && jt.companyLogoAddress != ''">
                                          <img :src="url + jt.companyLogoAddress" alt="Company Logo">
                                        </span>
                                        <span v-else>
                                          <img src="../../assets/images/no-image.png" alt="No Image">
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div class="col-sm-6">
                                  <router-link class="text-decoration-none"
                                    :to="{ name: 'JobDetail', params: { id: jt.jobId } }" title="Job Detail">
                                    <div class="job-info">
                                      <div class="custom-job-title text-dark text-capitalize style-text-14">{{
                                        jt.jobTitle }}</div>
                                      <div class="style-company custom-muted-text style-text-14">{{ jt.companyName }}
                                      </div>
                                      <div class="style-job-metas">
                                        <div class="style-job-salary custom-muted-text style-text-14">
                                          <i class="fas fa-money-bill"></i>
                                          <span v-if="jt.packageTo === 0 || jt.packageTo === 1">Market Competitive
                                            Salary</span>
                                          <span v-else> Rs {{ jt.packageFrom }} - {{ jt.packageTo }}/Month</span>
                                        </div>
                                        <div class="location custom-muted-text style-text-14">
                                          <i class="text-second fa fa-map-marker" aria-hidden="true"></i>
                                          <span>{{ jt.cityName }}, {{ jt.countryName }}</span>
                                        </div>
                                      </div>
                                    </div>
                                  </router-link>
                                </div>
                                <div class="col-sm-3 text-center">
                                  <div>
                                    <a @click="showOrHideModal(jt)"
                                      class="btn btn-primary custom-employer-btn custom-btn-apply-now mb-2">EasyApply
                                      Now</a>
                                  </div>
                                  <div>
                                    <a @click="onSavingJob(jt)"
                                      class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark">Save</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </VueSlickCarousel>
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12">
                      <div class="text-center clear-bottom">
                        <router-link :to="{ name: 'AllFeaturedOrLatestJobs', params: { typeJob: 'FeatureJob' } }"
                          class="btn btn-theme text-decoration-none" href="#">
                          <i aria-hidden="true" class="fa fa-plus-circle"></i> View More
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="featuredJobs != null && featuredJobs.length != 0 ">
            <div class="">
              <div class="row">
                <div class="col-6">
                  <div class="style-latest-jobs">
                    <a href="#">
                      <div class="">
                        <h3>Recommended Jobs</h3>
                      </div>
                    </a>
                  </div>
                </div>
                <div class="col-6">
                  <div class="float-right">
                    <a @click="showPrevious()" class="style-hand-icon">
              <span
              ><arrow-left-circle-icon
                  size="1.9x"
                  class="custom-class back-btn"
              ></arrow-left-circle-icon
              ></span>
                    </a>

                    <a @click="showNext()" class="style-hand-icon">
              <span
              ><arrow-right-circle-icon
                  size="1.9x"
                  class="custom-class forward-btn"
              ></arrow-right-circle-icon
              ></span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="style-latest-jobs-slides">
              <div class="container">
                <div class="row no-gutters">
                  <div class="col-12">
                    <div class="style-multirow-slide">
                      <VueSlickCarousel class="container" v-bind="settings" ref="carousel_feature_jobs" v-if="featuredJobs.length">
                        <div class=" row-slider-item" v-for="jt in featuredJobs" :key="jt.jobId">
                          <div class="row no-gutters align-items-center">
                            <div class="col-sm-9">
                              <router-link
                                  class="text-decoration-none"
                                  :to="{ name: 'JobDetail',
                               params: { id: jt.jobId }
                             }"
                                  title="Job Detail">
                                <div class="row no-gutters align-items-center">

                                  <div class="col-sm-2">
                                    <div class="style-left-img">

                                     <span class="b-avatar badge-info rounded-circle custom-b-avatar" >
                                            <span  class="b-avatar-img">
                                              <span v-if="jt.companyLogoAddress != null && jt.companyLogoAddress != ''">
                                                 <img :src="url+jt.companyLogoAddress">
                                               </span>
                                               <span v-else>

                                                    <img src="../../assets/images/no-image.png" >


                                               </span>

                                            </span>

                                          </span>
                                    </div>
                                  </div>
                                  <div class="col-sm-10">

                                    <div class="custom-job-title text-dark text-capitalize style-text-14">{{ jt.jobTitle }}</div>
                                    <div class="style-company custom-muted-text style-text-14">{{ jt.companyName }}</div>
                                    <div class="style-job-metas">
                                      <div class="style-job-salary custom-muted-text style-text-14">
                                        <i class="fas fa-money-bill"></i>
                                        <span v-if="jt.packageTo === 0 || jt.packageTo === 1">Market Competitive Salary</span>
                                        <span v-else> Rs {{ jt.packageFrom }} - {{ jt.packageTo }}/Month</span>
                                      </div>
                                      <div class="location custom-muted-text style-text-14">
                                        <i
                                            class="text-second fa fa-map-marker"
                                            aria-hidden="true"
                                            data-v-3c8a640f=""
                                        ></i>
                                        <span>{{ jt.cityName }}, {{ jt.countryName }}</span>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </router-link>
                            </div>


                            <div class="col-sm-3">
                              <div class="mb-3" >
                               <span class="flex-grow-1" >
                                  <span>
                                    <a @click="showOrHideModal(jt)" class="btn btn-primary custom-employer-btn custom-btn-apply-now  mb-2  ">EasyApply Now</a>
                                  </span>
                                  </span>
                              </div>

                              <div class="">
                                <a class="btn btn-primary custom-employer-btn custom-btn-apply-now custom-btn-bookmark " @click="onSavingJob(jt)">Save</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </VueSlickCarousel>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-12">
                    <div class="text-center clear-bottom">
                      <router-link :to="{name: 'AllFeaturedOrLatestJobs', params:{ typeJob: 'FeatureJob'}}" class="btn btn-theme text-decoration-none" href="#">
                        <i aria-hidden="true" class="fa fa-plus-circle"> </i> View More</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div> -->
            <!-- Featured Jobs    -->
            <!--SPACER-->
            <div class="style-spacer"></div>
            <div v-if="companies != null && companies.length != 0">
              <div class="style-latest-jobs">

                <div class="">
                  <h3>Companies To Follow</h3>
                </div>

              </div>
              <div class="w3-card-2 pt-3 pb-3 ml-auto mr-auto" style="width:100%;max-height: 400px;overflow: auto">
                <div class="w3-container " v-for="(data, i) in companies" :key="i">
                  <!--                <img src="../assets/images/company.jpg" align="center" />-->
                  <div class="custom-row">
                    <span class="b-avatar badge-info rounded-circle custom-b-avatar">
                      <span class="b-avatar-img">
                        <span v-if="data.companyLogoAddress != null && data.companyLogoAddress != ''">
                          <img :src="url + data.companyLogoAddress">
                        </span>
                        <span v-else>

                          <img src="../../assets/images/no-image.png">


                        </span>

                      </span>

                    </span>
                    <div>
                      <p class="font-weight-bold custom-text-color m-0">{{ data.companyName }}</p>
                      <span>{{ data.countryName }}</span>
                    </div>


                    <div class="ml-auto">
                      <!--                      <div v-if="data.companyStatus === false">
                        <button class="btn btn-primary btn-apply" @click="onFollowUnfollowCompany(data)">+ Follow</button>
                      </div>
                      <div v-else>
                        <button class="btn btn-primary btn-apply" @click="onFollowUnfollowCompany(data)">- Unfollow</button>
                      </div>-->
                    </div>
                  </div>

                  <hr>
                </div>




              </div>
            </div>
            <!--SPACER-->
            <div class="style-spacer"></div>
          </div>

        </div>
        <!-- <div class="col-lg-3">
          <div
              class="ml-2 nav flex-column nav-tabs custom-nav-tabs mt-1 border-bottom-0"
              id="v-tabs-tab"
              role="tablist"
              aria-orientation="vertical"
          >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:2}}"
                role="tab"
                aria-selected="false"

            >
              <!--            v-for="(category, index) in siteSurveyCategories"
                          :key="index"
                          @click="getSubmittedPMWOByCategory(category)"-->
        <!-- MY SHORTLISTED JOBS </router-link
            >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:3}}"
                role="tab"
                aria-controls="v-tabs-profile"
                aria-selected="false"

            >MY HIRED JOBS </router-link
            >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:4}}"
                role="tab"
                aria-controls="v-tabs-messages"
                aria-selected="false"

            >MY REJECTED JOBS</router-link
            >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:5}}"
                role="tab"
                aria-controls="v-tabs-settings"
                aria-selected="false"

            >MY INTERVIEWS</router-link
            >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:6}}"
                role="tab"
                aria-controls="v-tabs-settings"
                aria-selected="false"

            >MY CONTACTED JOBS</router-link
            >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:7}}"
                role="tab"
                aria-controls="v-tabs-settings"
                aria-selected="false"

            >MY SAVED JOBS</router-link
            >
            <router-link
                class="nav-link custom-nav-link custom-nav-job active mb-1"
                data-toggle="tab"
                :to="{name:'EmployeeJobs', params:{status:8}}"
                role="tab"
                aria-controls="v-tabs-settings"
                aria-selected="false"

            >MY EXPIRED JOBS</router-link
            >
          </div> 
        </div> -->
      </div>
    </div>

    <div class="modal fade p-0" id="addorEditModal" tabindex="-1" role="dialog" aria-labelledby="addorEditModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <form class="modal-content" @reset="reset()" v-if="show" autocomplete="off">
          <div class="modal-header">
            <h5 class="modal-title" id="addorEditModalLabel">
              {{ modalTitle }}
            </h5>


            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div v-if="profileSection">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3 form-group row no-gutters" v-if="profileImage">
                    <label class="col-sm-3 col-form-label col-form-label-sm">Choose Profile Image</label>

                    <div class="col-sm-9">
                      <b-form-file id="file-small" accept="image/*" size="sm" @change="onSelectImage"></b-form-file>
                    </div>
                  </div>
                  <div class="mb-3 form-group row no-gutters" v-if="uploadCV">

                    <label class="col-sm-3 col-form-label col-form-label-sm">Choose File</label>
                    <div class="col-sm-9">
                      <b-form-file id="file-small" accept=".pdf" size="sm" @change="onSelectCV"></b-form-file>
                    </div>
                    <h6>Please upload your CV in PDF format, Maximum size for the CV should be 2 MB.</h6>
                  </div>
                  <div class="mb-3 form-group row no-gutters" v-if="uploadpdf">

                    <label class="col-sm-3 col-form-label col-form-label-sm">Choose File</label>
                    <div class="col-sm-9">
                      <b-form-file id="file-small" accept=".pdf" size="sm" @change="onSelectpdf"></b-form-file>
                    </div>
                    <h6>Please upload your Assessment PDF, Maximum size for the Assessment should be 2 MB.</h6>
                  </div>

                </div>
              </div>
            </div>
            <!-- Add/Edit Experience Start  -->
            <div v-if="experienceSection">
              <AddEditExperienceComponent :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord"
                :empprojectrecord="employeeProjectRecord" @navigate-form="navigate" @reset-page="refreshPage"
                @toggle-modal="closeModal"></AddEditExperienceComponent>
            </div>
            <!-- Add/Edit Experience End  -->
            <!-- Add/Edit Education Start  -->


            <div v-if="educationSection">

              <AddEditEducationComponent :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord"
                @navigate-form="navigate" @reset-page="refreshPage" @toggle-modal="closeModal">
              </AddEditEducationComponent>

            </div>
            <!-- Add/Edit Education End  -->
            <!-- Add/Edit Skill Start  -->
            <div v-if="skillSection">
              <AddEditSkillsComponent :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord"
                @reset-page="refreshPage" @toggle-modal="closeModal"></AddEditSkillsComponent>

            </div>
            <!-- Add/Edit Skill End  -->
            <!-- Add/Edit Skill Start  -->
            <div v-if="ProjectSection">
              <AddEditProjectComponent :key="rerendercomponentKey" :title="modalTitle" :emprecord="employeeRecord"
                :empprojectrecord="employeeProjectRecord" @reset-page="refreshPage" @toggle-modal="closeModal">
              </AddEditProjectComponent>

            </div>
            <!-- Add/Edit Skill End  -->
            <!-- Add/Edit Skill Start  -->
            <div v-if="courseSection">
              In Maintenance mode

            </div>
            <!-- Add/Edit Skill End  -->
          </div>
          <div class="modal-footer" v-if="isModalFooterVisible">
            <div v-if="showCloseButton">
              <b-button id="closeButton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                type="button" size="sm" variant="outline-dark" data-dismiss="modal">Close
              </b-button>
            </div>
            <div v-if="showResetButton">
              <b-button id="resetButton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                type="reset" size="sm" variant="outline-dark">Reset
              </b-button>
            </div>

            <div>
              <b-button v-if="showSaveButton" class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                @click="AddOrUpdate()" size="sm" variant="outline-dark">Save
              </b-button>
            </div>

          </div>
        </form>
      </div>
    </div>
    <!-- Add Modal End -->
    <!-- Delete Modal Start -->
    <DeleteModal v-if="isModalVisible" :title="modalTitle" :exp-record="experienceRecord" :pro-record="projectRecord"
      :edu-record="educationRecord" :skill-record="skillRecord" @reset-page="refreshPage" @toggle-modal="closeModal">
    </DeleteModal>
    <!--          <div
              class="modal fade"
              id="deleteModal"
              tabindex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">Delete</h5>
                  <button
                      type="button"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <span class="mr-4 ml-2">
                    <b-icon
                        icon="exclamation-triangle"
                        variant="danger"
                        scale="2"
                    ></b-icon>
                  </span>
                  <span> Are you sure you want to delete this {{ modalTitle }}? </span>
                </div>
                <div class="modal-footer">
                  <div>
                    <b-button
                        class="form-control form-control-sm custom-form-btn custom-close-btn"
                        type="button"
                        size="sm"
                        variant="secondary"
                        data-dismiss="modal"
                    >
                      Close
                    </b-button>
                  </div>
                  <div>
                    <b-button
                        class="form-control form-control-sm custom-form-btn custom-btn-gotopage"
                        @click="deleteRecord()"
                        type="button"
                        size="sm"
                        variant="outline-dark"
                        data-dismiss="modal"
                    >
                      Delete
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </div>-->
    <!-- Delete Modal End -->


    <!-- Complete profile Modal-->
    <div class="">

      <CPModal v-if="isModalVisible" :save-job-status="isSaveJobStatus" :signInOrSignUpSection="isSignInOrSignUpSection"
        :employeeProfileFormSection="isEmployeeProfileFormSection" :show-alert-msg="isShowAlertMsg"
        :show-profile-info-card="isShowProfileInfoCard" :apply-job-confirmation-section="isApplyJobConfirmationSection"
        :selected-job-record="jobRecord" @get-strength="recievedStrength" @toggle-modal="toggleModal">

      </CPModal>
    </div>

  </div>
</template>
<script>
import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";

import { ArrowLeftCircleIcon } from "vue-feather-icons";
import { ArrowRightCircleIcon } from "vue-feather-icons";
import { VueperSlide, VueperSlides } from "vueperslides";

import axios from "axios";
import "../../assets/css/custom-css/animate-heading.css"
import VueApexCharts from 'vue-apexcharts'
import ProfileStrength from "@/components/employeeComponents/ProfileStrengthComponent";
import CPModal from "../../components/employeeComponents/CompleteProfileModalComponent"
import _ from "underscore";
import moment from "moment";
import { object } from "underscore";
import { nextTick, ref } from 'vue';
Vue.use(VueApexCharts)

Vue.component('apexchart', VueApexCharts)
export default {
  name: "EmployeeDashboard",
  components: {
    CPModal,
    ProfileStrength,
    VueSlickCarousel,
    ArrowLeftCircleIcon,
    ArrowRightCircleIcon,
  },
  data() {
    return {
    // This is the file cv selected from the user 
    selectedFileName: '',
    show :true,
    skillSection: false,
      ProjectSection: false,
      courseSection: false,
      isModalFooterVisible: true,

    barChartOptions: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'rounded'
        }
      },
      xaxis: {
        categories: [] // Categories will be populated dynamically
      },
      yaxis: {
        min: 10,  // Minimum value of the Y-axis will be 10
        tickAmount: 5, // Number of ticks you want on the Y-axis (adjust as needed)
        labels: {
          formatter: function(val) {
            return Math.floor(val); // This ensures that labels are rounded to whole numbers
          }
        },
        forceNiceScale: true, // Forces the y-axis to use a "nice" scale (e.g., round to nearest integers)
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: 'Job Applications',
        align: 'center'
      }
    },
    barChartSeries: [{
      name: 'Applications',
      data: [] // Data will be populated dynamically
    }],
  
      lineChartOptions: {
        chart: {
          type: 'line',
          height: 350,
          toolbar: {
            show: true
          }
        },
        stroke: {
          curve: 'smooth',
        },
        colors: ['#000000'],
        title: {
          text: 'Profile Views',
          align: 'center'
        },
        xaxis: {
          categories: []
        },
        dataLabels: {
          enabled: false
        },
        tooltip: {
          x: {
            format: 'MMM yyyy'
          }
        },
        grid: {
          borderColor: '#f1f1f1',
          show: true
        }
      },
      lineChartSeries: [{
        name: 'Views',
        data: []
      }],
      url: axios.defaults.baseURL,
      userImage: null,
//this is the documnets upload btn
      selectedCVFileName: null,
      selectedCVFile: null,
      removeCVFileName: "",
      fullFileName:"",//this is for the resume full name 
      // selectedAssessmentFileNames: [],
      selectedAssessmentFiles: [],
      removedAssessmentFilesName:"",
      employeeDocuments:[],
      uploadCVBtn: true,
      updateCvBtn: false,

      uploadCV: true,
      updateCVBtn: true,
      uploadAssessmentBtn: true,
      updateAssessmentBtn: true,

      uploadFile: null,
      uploadpdf: null,
      uploadPdfFile: false,
      emp: {
        FullName: "",
        FatherName: "",
        CNIC: "",
        DateofBirth: "",
        Gender: 0,
        MaritalStatusId: 0,
        Email: "",
        Address: "",
        Address1: "",
        Area: "",
        CityId: 0,
        EmployeeImage: "",
        Resume: "",
        FileName: "",
        PDfName: "",
        PdfFileName: "",
        CountryId: 0,
        OtherCityName: "",
        code: 0,
        networkCode: 0,
        mobileNumber: null,
        PhoneNumber: "",
        Domicile: "",
        Languages: "",
        Sector: "",
        YearOfExperienceId: 0,
        DepartmentId: 0,
        CareerLevelId: 0,

        CurrentSalary: 0,
        ExpectedSalary: 0,
        JobTypId: 0,
        ProfessionalSummary: "",
        LinkedInLink: "",
        FacebookLink: "",
        GooglePlusLink: "",
        TwitterLink: "",
        MomReturn: 0,
        SpecialNeedyPerson: 0,
        Graduate: 0,
        LabourManPower: 0,
        Hecancy: 0,
        //Graduate
        AvailableDate: new Date(),
        GraduateWorkPlacementTypeId: 0,
        ServiceDescription: "",
        SkillsCategoryId: 0,
        SkillsSubCategoryId: 0,
        SubCategory: "",


        //Special Needy
        DisabilityInformationTitle: "",
        DisabilityTypeId: 0,
        DisabilityCertificate: 0,
        /* DisabilitySeverityPhysical: 0,
         DisabilitySeverityHearingSpeech: 0,
         DisabilitySeverityVisual: 0,
         DisabilitySeverityIntellectual: 0,*/
        DisabilitySeverity: 0,
        IsKnowSignLangauge: 0,
        CauseOfDiabilityTypeId: 0,
        SpecialAssistanceRequirment: "",
        KindofJob: "",
        FillBy: 0,

        //Mom Return
        CurrentEmployementStatus: 0,
        StartCareer: new Date(),
        EndCareer: new Date(),
        /*CPStartMonth: 0,
        CPStartYear: 0,
        CPEndMonth:0,
        CPEndYear: 0,*/
        CPDescription: "",
        WeeklyWorkingHour: 0,
        WorkLocation: 0,
        TravelMiles: 0,
        TravelTimePercent: 0,


      },
      appliedJobsData: [],
      viewedJobsData: [],
       lineChartOptions: {
      //   chart: {

      //     type: "bar",
      //     toolbar: {
      //       show: !1
      //     }
      //   },
      //   plotOptions: {
      //     bar: {
      //       horizontal: !1,
      //       columnWidth: "45%",
      //       endingShape: "rounded"
      //     }
      //   },
      //   modalTitle: "",
      //   dataLabels: {
      //     enabled: !1
      //   },
      //   stroke: {
      //     show: !0,
      //     width: 2,
      //     colors: ["transparent"]
      //   },
      //   colors: ["#5b73e8", "#f1b44c"],
      //   grid: {
      //     borderColor: "#f1f1f1"
      //   },
      //   fill: {
      //     opacity: 1
      //   },
      //   tooltip: {
      //     y: {
      //       formatter: function (e) {
      //         return e
      //       }
      //     }
      //   },
         xaxis: {
          categories: []
        }
       },
       lineChartSeries: [{
      //   name: "No of Jobs Applied",
        data: []
       }],
      pieChartSeries: [44, 55, 13, 43, 22, 80],
      pieChartOptions: {
        chart: {

          type: "pie"
        },
        labels: ["Jul-20", "Aug-20", "Sep-20", "Oct-20", "Nov-20", "Dec-20"],
        colors: ["#34c38f", "#5b73e8", "#f1b44c", "#50a5f1", "#f46a6a", "#ea216c"],
        legend: {
          show: !0,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: !1,
          fontSize: "14px",
          offsetX: 0
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart: {
              height: 240,

            },
            legend: {
              show: !1
            }
          }
        }]
      },
      list: [],
      fullName: "",
      mobileNumber: null,
      code: 0,
      networkCode: 0,
      email: "",
      dob: "",
      facebookLink: "",
      linkedInLink: "",
      twitterLink: "",
      empGender: 0,
      googlePlusLink: "",
      cnic: "",
      currentSalary: 0,
      CountryId: 0,
      OtherCityName: "",
      domicile: "",
      yearOfExperienceId: 0,
      expectedSalary: 0,
      maritalStatusId: 0,
      cityId: 0,
      phoneNumberLink: "tel:",
      linkMobileNo: "",
      emailLink: "mailto:",

      maritalStatusList: [],

      countries: null,
      cities: null,
      settings: {
  infinite: true,
  slidesToShow: 2,  // Default for larger screens
  speed: 500,
  rows: 1,          // Show 1 row only
  slidesPerRow: 1,  // Show 1 slide per row
  responsive: [
    {
      breakpoint: 1024,  // iPad Air (Portrait mode)
      settings: {
        slidesToShow: 1,  // Show only 1 job card
        slidesPerRow: 1
      }
    },
    {
      breakpoint: 768,  // Tablets like Samsung Galaxy Tab, Oppo tablets
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1
      }
    },
    {
      breakpoint: 600,  // Large phones (Samsung, Oppo)
      settings: {
        slidesToShow: 1,
        slidesPerRow: 1
      }
    },
    {
      breakpoint: 480,  // Smaller mobile screens
      settings: {
        slidesToShow: 1,  
        slidesPerRow: 1
      }
    }
  ]
},


      // settings: {
      //   infinite: true,
      //   slidesToShow: 1,
      //   speed: 500,
      //   rows: 2,
      //   slidesPerRow: 3
      // }
      status: 1,
      empGraduate: 0,
      empMumsReturnToWork: 0,
      empSpecialNeedyPerson: 0,
      empLabourManPower: 0,
      empHecancy: 0,
      selectedCategory: "-1",
      categories: null,
      employeelist: null,
      employeeId: 0,
      employeeSkill: null,
      employeeExperience: null,
      employeeEducation: null,

      yearsOfExperiences: [],

      calculatedAge: 0,

      latestExperience: {},
      latestDegreeLevel: {},


      countryCodes: [
        { value: 1, text: "+92" },
        { value: 2, text: "+93" },
        { value: 3, text: "+94" },
      ],

      networkCodes: [
        { value: 1, text: "300" },
        { value: 2, text: "301" },
        { value: 3, text: "302" },
        { value: 4, text: "303" },
        { value: 5, text: "304" },
        { value: 6, text: "305" },
        { value: 7, text: "306" },
        { value: 8, text: "307" },
        { value: 9, text: "308" },
        { value: 10, text: "309" },
        { value: 11, text: "311" },
        { value: 12, text: "312" },
        { value: 13, text: "313" },
        { value: 14, text: "314" },
        { value: 15, text: "315" },
        { value: 16, text: "320" },
        { value: 17, text: "321" },
        { value: 18, text: "322" },
        { value: 19, text: "323" },
        { value: 20, text: "324" },
        { value: 21, text: "325" },
        { value: 22, text: "330" },
        { value: 23, text: "331" },
        { value: 24, text: "332" },
        { value: 25, text: "333" },
        { value: 26, text: "334" },
        { value: 27, text: "335" },
        { value: 28, text: "336" },
        { value: 29, text: "337" },

        { value: 30, text: "340" },
        { value: 31, text: "341" },
        { value: 32, text: "342" },
        { value: 33, text: "343" },
        { value: 34, text: "344" },
        { value: 35, text: "345" },
        { value: 36, text: "346" },
        { value: 37, text: "347" },

      ],
      //Email Status
      empStatus: 0,

      //Applied Date
      appliedDate: [],
      //Apply job
      job: {

        jobId: 0,
        employeeId: 0,
        expectedSalary: 0,
        status: 1,
        isApplicantViewed: false
      },
      saveJob: {
        saveJobId: 0,
        jobId: 0,
        employeeId: 0,
        isActive: true

      },

      //Complete Profile Modal
      jobID: 0,
      jobId: 0,
      jobRecord: null,
      jobsStatuses: null,

      isSaveJobStatus: false,

      isModalVisible: false,
      isSignInOrSignUpSection: false,
      isEmployeeProfileFormSection: false,
      isShowAlertMsg: false,
      isShowProfileInfoCard: false,
      isApplyJobConfirmationSection: false,

      showAlertMsg: false,
      modalDescription: "",

      //Complete Profile Notification
      updateProfileAlertMsg: false,
      emailVerifyAlertMsg: false,


      //jobs
      featuredJobs: null,
      companies: null,
      followStatus: true,
      profileStrength: 0,

      info: [],
      componentKey: 0,

      //assessment
      disableEasyApplyButton: false,
      showAssessmentButton: false,
      unansweredQuestions: [],

    };
  },
  methods: {
    getJobApplicationChart() {
      

        axios.get("/api/Employee/GetsDashboard")
          .then(response => {
            const data = response.data.data;
     
      
            // Extract months and applications data from the API response
            const months = [
              data.sixthmonth,   // June
              data.fifthmonth,   // July
              data.fourthmonth,  // August
              data.thirdmonth,   // September
              data.secondmonth,  // October
              data.firstmonth    // November
            ];
            
      
            const applications = [
              data.sixth,   // June applications count
              data.fifth,   // July applications count
              data.fourth,  // August applications count
              data.third,   // September applications count
              data.second,  // October applications count
              data.first    // November applications count
            ];
            
            this.barChartOptions.xaxis.categories = months;
            // console.log("months",this.barChartOptions.xaxis.categories);
            this.barChartSeries[0].data = applications;
            // console.log("applications",this.barChartSeries[0].data);
      this.rerender();  
          })
          .catch(error => {
            console.error("Error fetching job application data:", error);
          });
      },
   
  getJobApplicationViewCount() {
    axios.get("/api/Employee/GetApplicantView")
      .then(response => {
        const data = response.data.data; // Assume this is the API response object

        // Extract months and views from the API response
        const months = [
          data.sixthmonth,   // Jun
          data.fifthmonth,   // Jul
          data.fourthmonth,  // Aug
          data.thirdmonth,   // Sep
          data.secondmonth,  // Oct
          data.firstmonth    // Nov
        ];

        const views = [
          data.sixth,  // Views in Jun
          data.fifth,   // Views in Jul
          data.fourth,  // Views in Aug
          data.third,   // Views in Sep
          data.second,  // Views in Oct
          data.first    // Views in Nov
        ];

        // Update the x-axis categories (months) and the y-axis data (views)
        this.lineChartOptions.xaxis.categories = months;  // Set the categories to the months
        this.lineChartSeries[0].data = views;             // Set the views data
        this.rerender();
        console.log("Updated chart data", {
          categories: months,
          seriesData: views
        });
      })
      .catch(error => {
        console.error("Error fetching job application views:", error);
      });
  },

    triggerFileInput(refName) {
      this.$refs[refName].click(); // Dynamically trigger the correct file input
    },
    extractFileName(fullFileName) {
  // Split by underscore and return the second part
  const parts = fullFileName.split('_');
  return parts.length > 1 ? parts[1] : fullFileName; // Default to original name if no underscore found
},
handleCvUploadFileChange(event) {
  const uploadedCV = event.target.files?.[0];

  if (!uploadedCV) {
    alert("No file selected. Please choose a file.");
    return;
  }

  console.log("Uploaded file:", uploadedCV);  // Debugging line

  // Check if the file type is PDF
  if (uploadedCV.type === "application/pdf") {
    this.selectedCVFileName = uploadedCV.name;
    this.selectedCVFile = uploadedCV;
    console.log("Selected CV:", this.selectedCVFileName);
  } else {
    alert("Please select a valid PDF file.");
  }

  console.log("Selected assessments:", uploadedCV.name);  // Logs the file name
},

    handleUpdateCvFileChange(event) {
  const updatedCV = event.target.files?.[0];
  if(this.selectedCVFileName!=null){
    alert("Only one file can upload;delete the previous resume first");
    return;
  }

  if (!updatedCV) {
    alert("No file selected. Please choose a file.");
    return;
  }

  if (updatedCV.type === "application/pdf" || updatedCV.name.toLowerCase().endsWith(".pdf")) {
    this.selectedCVFileName = updatedCV.name;
    this.selectedCVFile = updatedCV; // Use updatedCV here
    console.log("Selected CV:", this.selectedCVFileName);
  } else {
    alert("Please select a valid PDF file.");
    this.selectedCVFileName = "";
    this.$refs.cvInput.value = ""; // Reset file input
  }

  console.log("Selected update cv:", updatedCV.name);
},
    handleAssessmentFileChange(event) {
  const uploadedAssessments = event.target.files;

  if (!uploadedAssessments || uploadedAssessments.length === 0) {
    alert("No file selected. Please choose a file.");
    return;
  }
  if (uploadedAssessments.length > 1) {
    alert("You can only upload 1 file.");
    return;
  }

  // Check if employee data is loaded
  if (!this.employeeId || this.employeeId === 0) {
    alert("Employee data is not yet loaded. Please wait and try again.");
    this.$refs.assessmentUpdated.value = ""; // Reset file input
    return;
  }

  // Check if the uploaded file is a PDF
  if (uploadedAssessments[0].type === "application/pdf" && this.employeeDocuments.length == 0) {
    // Handle the file and add it to employeeDocuments
    const file = uploadedAssessments[0];

    const newDoc = {
      assessmentDocumentId: 0, // Set to 0 or assign dynamically if needed
      employeeId: this.employeeId,
      fileName: file.name, // Use the correct property to get the file name
      uploadedDate: new Date().toISOString() // Set current date as the upload date
    };

    this.employeeDocuments.push(newDoc); // Add the document object

    // Push the file to selectedAssessmentFiles
    this.selectedAssessmentFiles.push(file);

    console.log("Uploaded assessment documents:", this.employeeDocuments);
    console.log("Selected assessment files:", this.selectedAssessmentFiles);
  } else {
    alert("The file must be a PDF and only 1 file is allowed.");
    this.$refs.assessmentUpdated.value = ""; // Reset file input
  }
},
handleAssessmentUpdateFileChange(event) {
  // Get the files from the input
  const uploadedAssessments = event.target.files;

  // Check if no file is selected
  if (!uploadedAssessments || uploadedAssessments.length === 0) {
    alert("No file selected. Please choose a file.");
    return;
  }

  // Check if more than one file is selected
  if (uploadedAssessments.length > 1) {
    alert("You can only upload 1 file.");
    return;
  }

  // Check if employee data is loaded
  if (!this.employeeId || this.employeeId === 0) {
    alert("Employee data is not yet loaded. Please wait and try again.");
    this.$refs.assessmentUpdated.value = ""; // Reset file input
    return;
  }

  // Get the first file from the FileList (since only one file is expected)
  const file = uploadedAssessments[0];

  // Validate if the file is a PDF
  if (file.type !== "application/pdf" && !file.name.toLowerCase().endsWith(".pdf")) {
    alert("Please upload a PDF file.");
    this.$refs.assessmentUpdated.value = ""; // Reset file input
    return;
  }

  // If employeeDocuments is empty, proceed to add the file
  if (this.employeeDocuments.length === 0) {
    // Create the new document model for the selected file
    const newDoc = {
      assessmentDocumentId: 0, // You can set this dynamically if needed
      employeeId: this.employeeId,
      fileName: file.name, // Use the correct property to get the file name
      uploadedDate: new Date().toISOString() // Store the current date as the upload date
    };

    // Push the new document to employeeDocuments
    this.employeeDocuments.push(newDoc);

    // Push the file to selectedAssessmentFiles (if you need this for further handling)
    this.selectedAssessmentFiles.push(file);

    console.log("Uploaded file:", file);
    console.log("Selected files:", this.selectedAssessmentFiles);
  } else {
    alert("You can upload only 1 file.");
    this.$refs.assessmentUpdated.value = ""; // Reset file input
  }
},
removeResume(fullFileName){
  const isConfirmed = window.confirm("Are you sure you want to delete this file?");
  if (isConfirmed) {
  if(fullFileName!="" && this.removeCVFileName==""){
    this.removeCVFileName=this.fullFileName;
    this.selectedCVFileName="";
  }}
},
    removeFile(doc, index) {
      {
        const isConfirmed = window.confirm("Are you sure you want to delete this file?");
        if (isConfirmed) {
          this.employeeDocuments.splice(index, 1);

          if (this.removedAssessmentFilesName == "") {
            this.removedAssessmentFilesName = doc.fileName;
          }
          console.log("Removed doc application by system:", this.removedAssessmentFilesName);
          console.log("Removed doc by system:", doc);
          console.log("employeeDocuments :", this.employeeDocuments);
        }
      }
    },
saveDocumnet() {
  const formData = new FormData();
  formData.append("employeeId", this.employeeId);

  // Append resume and removed resume if applicable
  if (this.selectedCVFile) {
    formData.append("resumeUploaded", this.selectedCVFile);
  }
  if (this.removeCVFileName) {
    formData.append("removeResume", this.removeCVFileName);
  }

  // Append assessment files (up to 5 files)
  this.selectedAssessmentFiles.slice(0, 5).forEach((file, index) => {
    formData.append(`uploadedAssessment[${index}]`, file);
  });

  // Append removed assessment names
  
  formData.append("removeAssessmentNames", this.removedAssessmentFilesName);
  
console.log(formData)
  axios.post("/api/Employee/UpSertEmployeeDocuments", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })
  .then(response => {
    console.log(response);
    this.emp.Resume = response.data.fileUrl || "Uploaded successfully!";
    alert("Documents uploaded successfully.");
  })
  
},
  getSelectedValues() {
      var radioButtons = [];
      var selectedValues = {};

      // Assuming you have radio buttons with names 'q1' to 'q24'
      for (var i = 1; i <= 24; i++) {
        radioButtons[i] = document.getElementsByName('q' + i);
        var userid = 0;
        var selectedInGroup = false;

        // Loop through the radio buttons to find the selected one
        for (var j = 0; j < radioButtons[i].length; j++) {
          if (radioButtons[i][j].checked) {

            selectedInGroup = true;

            selectedValues['q' + i] = radioButtons[i][j].value;
            var entry = {
              JobId: this.jobId,
              UserId: userid,
              Username: "test",
              QuestionNo: radioButtons[i][j].name,
              Answers: radioButtons[i][j].value
            };
            this.SelectedValues.push(entry);
            break;
          }
        }
        if (!selectedInGroup) {
          // Record unanswered question number
          this.unansweredQuestions.push(i);
        }
      }

      if (this.unansweredQuestions.length > 0) {
        // Show an alert or handle unanswered questions as needed
        alert('Please select an option for questions: ' + this.unansweredQuestions.join(', '));
        // Clear the unanswered questions array for the next attempt
        this.unansweredQuestions = [];
        return;
      }

      console.log(this.SelectedValues);
      this.disableEasyApplyButton = true;

      axios
        .post("/api/Job/InsertAssesment", this.SelectedValues)
        .then(res => {
          // code that we will 'try' to run

          console.log("InsertAssesment", res);
          console.log("InsertAssesment data", res.data);
          console.log("InsertAssesment data2", res.data.data);
          if (res.data.data == 3000) {
            alert('Already submitted the Assessment!');
            window.location.reload();
          }
          if (res.data.data == 2000) {
            alert('Assesment saved Successfully!');
            window.location.reload();
          }
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
      console.log(selectedValues);
    },


    getAll() {
      this.rerender();

      this.employeelist = null;
      axios.get('/api/Employee/GetEmployeeProfile')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }



          this.employeelist = res.data.employee;
          console.log("employeelist : ", this.employeelist);
        
    

    // Push each document into the array
    res.data.employeeAssessmentDocument.forEach(doc => {
      this.employeeDocuments.push(doc);
      
    });
    if(res.data.employeeAssessmentDocument.length>0){
      this.uploadAssessmentBtn=false;
      this.updateAssessmentBtn=true;

    }else{
      this.uploadAssessmentBtn=true;
      this.updateAssessmentBtn=false;

    }
    
    console.log ("this is the docs ::::",this.employeeDocuments);
          this.employeeId = this.employeelist.employeeId;


          this.userImage = this.employeelist.employeeImage;


          console.log("img : ", this.url + this.userImage);
          this.fullName = this.employeelist.fullName;
          for (let option in this.countryCodes) {
            if (this.employeelist.code === this.countryCodes[option].value) {
              this.code = this.countryCodes[option].text;
            }
          }
          console.log("country Code : ", this.code);
          //this.code = this.employeelist.code;
          for (let option in this.networkCodes) {
            if (this.employeelist.networkCode === this.networkCodes[option].value) {
              this.networkCode = this.networkCodes[option].text;
            }
          }
          console.log("network Code : ", this.networkCode);
          //this.networkCode = this.employeelist.networkCode;

          this.mobileNumber = this.employeelist.mobileNumber;
          this.linkMobileNo = this.code + this.networkCode + this.mobileNumber;
          this.email = this.employeelist.email;
          this.empGender = this.employeelist.gender;
          this.dob = this.employeelist.dateofBirth;
          console.log("DOB : ", this.dob);
          console.log("calculated Age : ", this.caculateAge(this.dob));
          this.calculatedAge = this.caculateAge(this.dob);
          this.facebookLink = this.employeelist.facebookLink;
          this.linkedInLink = this.employeelist.linkedInLink;
          this.twitterLink = this.employeelist.twitterLink;
          this.googlePlusLink = this.employeelist.googlePlusLink;
          this.cnic = this.employeelist.cnic;
          this.currentSalary = this.employeelist.currentSalary;
          this.domicile = this.employeelist.domicile;
          this.CountryId = this.employeelist.countryId;
          this.OtherCityName = this.employeelist.cityName;
          this.maritalStatusId = this.employeelist.maritalStatusId;
          this.yearOfExperienceId = this.employeelist.yearOfExperienceId;
          this.expectedSalary = this.employeelist.expectedSalary;
          this.job.expectedSalary = this.employeelist.expectedSalary;
          this.cityId = this.employeelist.cityId;
          this.professionalSummary = this.employeelist.professionalSummary;
          this.empGraduate = this.employeelist.graduate;
          this.empMumsReturnToWork = this.employeelist.momReturn;
          this.empSpecialNeedyPerson = this.employeelist.specialNeedyPerson;
          this.empLabourManPower = this.employeelist.labourManPower;
          this.Resume = this.employeelist.resume;
          //This is the show of the resume/cv Name if already uploaded else show no resume Uploaded
          if (this.employeelist.resume != null && this.employeelist.resume != '') {
            this.fullFileName = this.employeelist.resume.split('\\').pop(); // Extract file name from the path
            const fileNameParts = this.fullFileName.split('_'); // Split the file name by "_"

            // Check if there is a second part after the underscore
            this.selectedCVFileName = fileNameParts.length > 1 ? fileNameParts[1] : fullFileName;
          }
        


          this.empHecancy = this.employeelist.hecancy

          this.empStatus = this.employeelist.empStatus;
          // if (this.emp.PDfName == null) {
          //   this.uploadpdfBtn = true;
          //    this.updatepdfBtn = false;
          // }
          // else {
          //   this.uploadpdfBtn = false;
          //   this.updatepdfBtn = true;
          // }
          if (this.employeelist.resume == null) {
            this.uploadCVBtn = true;
            this.updateCVBtn = false;
          }
          else {
            this.uploadCVBtn = false;
            this.updateCvBtn = true;
          }

          console.log("empStatus : ", this.empStatus);

          this.employeeSkill = res.data.eMPSkills;
          console.log("employeeSkill : ", this.employeeSkill);
          this.employeeExperience = _.sortBy(res.data.eMPExperiences, 'startDateMonthYear').reverse();

          if (this.employeeExperience.length != 0) {
            console.log("employeeExperience : ", this.employeeExperience);

            this.latestExperience = _.first(this.employeeExperience);
            console.log("this.latestExperience", this.latestExperience);
          }


          this.employeeEducation = _.sortBy(res.data.empEducations, 'degreeLevelId').reverse();

          if (this.employeeEducation.length != 0) {
            console.log("employeeEducation : ", this.employeeEducation);
            this.latestDegreeLevel = _.first(this.employeeEducation);
            console.log("this.latestDegreeLevel", this.latestDegreeLevel);
          }


          console.log("getAll:", this.profileStrength);
          this.CalculatePercentage();

          //this.appliedJobsStatus();
          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },


    getDashboardCharts() {
      this.rerender();

      // this.getAppliedJobsChart();
      // this.getViewedJobsChart();

    },
    NewModalUploadCV() {
      this.modalTitle = "Upload CV";
      this.reset();
      this.profileSection = true;
      this.profileImage = false;
      this.uploadCV = true;
      this.uploadpdf = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible = true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    NewModalUploadPdf() {
      this.modalTitle = "Upload Assessment";
      this.reset();
      this.profileSection = true;
      this.profileImage = false;
      this.uploadCV = false;
      this.uploadpdf = true;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible = true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    //Update Modal Profile Image
    EditModalUploadCV() {
      this.modalTitle = "Update CV";

      this.profileSection = true;
      this.profileImage = false;
      this.uploadCV = true;
      this.uploadpdf = false;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible = true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    EditModalUploadPdf() {
      this.modalTitle = "Update Assessment";

      this.profileSection = true;
      this.profileImage = false;
      this.uploadCV = false;
      this.uploadpdf = true;
      this.experienceSection = false;
      this.educationSection = false;
      this.skillSection = false;
      this.ProjectSection = false;
      this.courseSection = false;
      this.isModalFooterVisible = true;
      this.showSaveButton = true;

      this.showCloseButton = true;
      this.showResetButton = true;
    },
    // getAppliedJobsChart(){
    //   this.appliedJobsData = [];
    //   axios.get('/api/Employee/GetsDashboard')
    //       .then(res =>{




    //         this.appliedJobsData = res.data.data;
    //         console.log("appliedJobsData : ", this.appliedJobsData);



    //         // this.lineChartSeries = [
    //         //   {
    //         //     data: [this.appliedJobsData.sixth, this.appliedJobsData.fifth, this.appliedJobsData.fourth, this.appliedJobsData.third, this.appliedJobsData.second, this.appliedJobsData.first]
    //         //   }
    //         // ]
    //         // this.lineChartOptions =
    //         //     {
    //         //       xaxis: {
    //         //         categories: [this.appliedJobsData.sixthmonth, this.appliedJobsData.fifthmonth, this.appliedJobsData.fourthmonth, this.appliedJobsData.thirdmonth, this.appliedJobsData.secondmonth, this.appliedJobsData.firstmonth]
    //         //       }
    //         //     }


    //         // });
    //       }).catch(error => {
    //     console.log(error);
    //     this.errored = true;
    //   })
    //       .finally(() => {
    //         if (this.errored) {

    //           console.log("Error : Sorry there is no response");
    //           // this.$bvToast.toast("Sorry there is no response ", {
    //           //   toaster: "b-toaster-top-center",
    //           //   variant: "danger",

    //           //   solid: true,
    //           // });
    //         }
    //       });
    // },
    getViewedJobsChart() {
      this.viewedJobsData = [];
      axios.get('/api/Employee/GetApplicantView')
        .then(res => {




          this.viewedJobsData = res.data.data;
          console.log("viewedJobsData : ", this.viewedJobsData);



          // this.pieChartSeries = [this.viewedJobsData.sixth, this.viewedJobsData.fifth, this.viewedJobsData.fourth, this.viewedJobsData.third, this.viewedJobsData.second, this.viewedJobsData.first];


          // this.pieChartOptions =
          //     {
          //       labels: [this.viewedJobsData.sixthmonth, this.viewedJobsData.fifthmonth, this.viewedJobsData.fourthmonth, this.viewedJobsData.thirdmonth, this.viewedJobsData.secondmonth, this.viewedJobsData.firstmonth]

          //     }


          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    buttonsMethod() {
      // if (this.jobList.companyId == 212) {
      // If the company name is 'Unze London', show the assessment button and enable the easy apply button
      this.showAssessmentButton = false;
      this.disableEasyApplyButton = true;
      // } else {
      //   // If the company name is not 'Unze London', hide the assessment button and disable the easy apply button
      //   this.showAssessmentButton = true;
      //   this.disableEasyApplyButton = false;
      // }
    },
    submitAssessment(jobId) {
      // Your logic to submit the assessment modal
      this.jobId = jobId;
      // After submission, disable the easy apply button
      this.disableEasyApplyButton = true;
    },

    showOrHideModal(jobrecord) {
      this.jobRecord = jobrecord;
      if (this.$auth.check()) {
        console.log("chekondashboard : ", this.$auth.check());
        //this.$router.push({ name: "JobDetail" });




        if (this.profileStrength > 80) {
          console.log("total strength inside if statement after Employee Skill section: ", this.profileStrength)

          this.isModalVisible = true;

          this.isSignInOrSignUpSection = false;
          this.isEmployeeProfileFormSection = true;
          this.isShowAlertMsg = false;
          this.isShowProfileInfoCard = true;
          this.isApplyJobConfirmationSection = true;
          console.log("isApplyJobConfirmationSection :", this.isApplyJobConfirmationSection)
          console.log("job strength inside if statement: ", this.profileStrength)
          console.log("jobRecord inside if statement: ", this.jobRecord)
        }
        else {
          this.$bvToast.toast("Your profile Strength Must be Over 90%, Please Update your Profile", {
            toaster: "b-toaster-top-center",
            variant: "warning",

            solid: true
          });

          this.isModalVisible = true;
          console.log("job strength inside else statement: ", this.profileStrength)

          this.isSignInOrSignUpSection = false;
          this.isEmployeeProfileFormSection = true;
          this.isShowAlertMsg = true;
          this.isShowProfileInfoCard = false;
          this.isApplyJobConfirmationSection = false;



        }


      }
      else {
        console.log("chek : ", this.$auth.check());
        this.isModalVisible = true;
        this.isEmployeeProfileFormSection = false;
        //this.modalTitle = "Attention";
        this.isSignInOrSignUpSection = true;
        this.isShowAlertMsg = false;
        this.isShowProfileInfoCard = false;
        this.isApplyJobConfirmationSection = false;
        //this.modalDescription = "Please Register/Login Before Applying a Job"
      }
    },

    onSavingJob(jobrecord) {
      this.jobRecord = jobrecord;
      if (this.$auth.check()) {
        this.saveJob.jobId = Number(jobrecord.jobId);
        this.saveJob.employeeId = Number(this.employeeId);
        console.log("jobId + Emp ID : ", this.saveJob.jobId + this.saveJob.employeeId)
        axios
          .post("/api/Job/SaveJob", this.saveJob)
          .then(res => {
            // code that we will 'try' to run
            this.info = res.data;

            console.log("Add Response portion");

            console.log("response : ", this.info.msg);
          })
          .catch(error => {
            console.log("Errorrrrrr : ", error);
            this.errored = true;
          })
          .finally(() => {


            if (this.info.msg == "Job Already Save") {
              this.$bvToast.toast("You have Saved to the Job Already. ", {
                toaster: "b-toaster-top-center",
                variant: "warning",

                solid: true
              });
            }
            else {
              this.$bvToast.toast("Job Saved Successfully", {
                toaster: "b-toaster-top-center",
                variant: "success",

                solid: true
              });
            }
            this.getAll();
            this.getAllEmployees();


          });
      }
      else {
        this.$bvToast.toast("Please Sign Up / Sign In, Before Saving a job", {
          toaster: "b-toaster-top-center",
          variant: "warning",

          solid: true
        });
        setTimeout(this.showSignUpOrSignInModal(), 1000)
      }

    },

    showSignUpOrSignInModal() {
      this.isModalVisible = true;
      this.isSaveJobStatus = true;
      this.isEmployeeProfileFormSection = false;

      this.isSignInOrSignUpSection = true;
      this.isShowAlertMsg = false;
      this.isShowProfileInfoCard = false;
      this.isApplyJobConfirmationSection = false;
    },
    onApplyingJob(id) {

      this.job.jobId = Number(id);
      this.job.employeeId = Number(this.employeeId);

      axios
        .post("/api/Job/ApplyJob", this.job)
        .then(res => {
          // code that we will 'try' to run
          this.info = res.data;

          console.log("Add Response portion");

          console.log("response : ", this.info.msg);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          this.getAll();

          if (this.info.msg == "Employee Already Exist") {
            this.$bvToast.toast("You have applied to the Job Already. ", {
              toaster: "b-toaster-top-center",
              variant: "warning",

              solid: true
            });
          }
          else {
            this.$bvToast.toast("Job Applied Successfully", {
              toaster: "b-toaster-top-center",
              variant: "success",

              solid: true
            });
          }


        });
    },
    // AddOrUpdate() {
    //   if(this.modalTitle == "Change Profile Image"){
    //     this.onSubmit();
    //   }
    //   else if(this.modalTitle == "Upload CV"){
    //     this.onSubmit();
    //   }
    //   else if(this.modalTitle == "Update CV"){
    //     this.onSubmit();
    //   }
    //   else if (this.modalTitle == "Upload Assessment"){
    //     this.onpdfSubmit();
    //   }
    //   else if (this.modalTitle == "Update Assessment"){
    //     this.onpdfSubmit();
    //   }
    // },
    getCountryList() {
      this.countries = null;
      let model = {
        type: "Country"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.countries = res.data.data;

          console.log("Add Response portion");

          console.log("countries : ", this.countries);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },
    appliedJobsStatus() {
      this.jobsStatuses = null;
      axios.get('/api/Job/GetAllJobStatus')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }


          /*forEach((item, index) => {
        const country = {
          id: item.gnId,
          title: item.name,
        };*/
          let list = res.data.data
          let dateList = []


          this.jobsStatuses = list.slice().reverse();



          console.log("jobsStatuses : ", this.jobsStatuses);
          for (let date in this.jobsStatuses) {
            var given = moment(this.jobsStatuses[date].appliedDate, "YYYY-MM-DD");
            var current = moment().startOf('day');
            this.appliedDate[date] = moment.duration(current.diff(given)).asDays();
            //console.log("appliedDate : ", this.appliedDate);
          }
          console.log("this.appliedDate", this.appliedDate)
          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });

    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
      this.getAll();
    },



    recievedStrength(value) {
      this.profileStrength = value;
      console.log("On Home Page: ", this.profileStrength);
      this.checkProfile();
    },
    checkProfile() {
      if (this.profileStrength > 80 && this.empStatus != -1) {
        this.showAlertMsg = false;
      }
      else {
        this.showAlertMsg = true;
        if (this.profileStrength > 80) {
          this.updateProfileAlertMsg = false;
        }
        else {
          this.updateProfileAlertMsg = true;
        }
        if (this.empStatus != -1) {
          this.emailVerifyAlertMsg = false;
        }
        else {
          this.emailVerifyAlertMsg = true;
        }
      }
    },
    CalculatePercentage() {

      //Employee
      let FullName = 1.42;
      let FatherName = 1.42;
      let CNIC = 1.42;
      let DateofBirth = 1.42;
      let Gender = 1.42;
      let MaritalStatus = 1.42;

      let Address = 1.42;
      let Address1 = 1.42;
      let Area = 1.42;
      let CityId = 1.42;
      let EmployeeImage = 1.42;
      let Resume = 1.42;
      let CountryId = 1.42;
      let code = 1.42;
      let mobileNumber = 1.42;

      let Domicile = 1.42;
      let Languages = 1.42;
      let Sector = 1.42;
      let YearOfExperience = 1.42;
      let ExperienceLevel = 1.42;
      let CurrentSalary = 1.42;
      let ExpectedSalary = 1.42;
      // let JobTypId= 1.42;
      let ProfessionalSummary = 1.42;
      let LinkedInLink = 1.42;
      let FacebookLink = 1.42;
      let GooglePlusLink = 1.42;
      let TwitterLink = 1.42;
      let MomReturn = 1.42;
      let SpecialNeedyPerson = 1.42;
      let Graduate = 1.42;

      //experience
      let empExperienceId = 1.42;
      let jobTitle = 1.42;
      let company = 1.42;
      let industryId = 1.42;
      let teamManage = 1.42;
      let salary = 1.42;
      let cityId = 1.42;
      let isPakistan = 1.42;
      let startDateMonthYear = 1.42;
      let endDateMonthYear = 1.42;
      let currentlyWorking = 1.42;
      let experienceDescription = 1.42;
      let referenceEmail = 1.42;
      let referenceNumber = 1.42;
      let countryId = 1.42;
      let projectTitle = 1.42;
      let position = 1.42;
      let projectStartDateMonthYear = 1.42;
      let projectEndDateMonthYear = 1.42;
      let isProjectOngoing = 1.42;
      let projectDescription = 1.42;

      //education
      let empEducationId = 1.42;

      let degreeLevelId = 1.42;
      let degreeTitle = 1.42;
      let majorFieldId = 1.42;
      let institute = 1.42;
      let expectedGraduationYear = 1.42;
      let graduationCompletionYear = 1.42;
      let resultType = 1.42;
      let cgpaPercentage = 1.42;
      let countryGNId = 1.42;
      let stateGNId = 1.42;
      let educationDescription = 1.42;
      let academicProjectDetail = 1.42;
      let startDate = 1.42;
      let endDate = 1.42;
      let projectDetails = 1.42;


      let empSkillId = 1.42;
      let skillsSubCategoryId = 1.42;
      let skillLevelOutof100 = 1.42;


      let percent = 0;
      if (this.employeelist.fullName != "") {
        percent += FullName;
        console.log("percent1 : ", percent);
      }
      if (this.employeelist.fatherName != "" && this.employeelist.fatherName != null) {
        percent += FatherName;
        console.log("percent2 : ", percent);
      }
      if (this.employeelist.cnic != "") {
        percent += CNIC;
        console.log("percent3 : ", percent);
      }
      if (this.employeelist.dateofBirth != null) {
        percent += DateofBirth;
        console.log("percent4 : ", percent);
      }
      if (this.employeelist.gender != 0) {
        percent += Gender;
        console.log("percent5 : ", percent);
      }
      if (this.employeelist.maritalStatusId != 0) {
        percent += MaritalStatus;
        console.log("percent6 : ", percent);
      }
      if (this.employeelist.address != null) {
        percent += Address;
        console.log("percent7 : ", percent);
      }
      if (this.employeelist.address1 != null) {
        percent += Address1;
        console.log("percent8 : ", percent);
      }
      if (this.employeelist.area != "") {
        percent += Area;
        console.log("percent9 : ", percent);
      }
      if (this.employeelist.cityId != 0) {
        percent += CityId;
        console.log("percent10 : ", percent);
      }
      if (this.employeelist.employeeImage != "") {
        percent += EmployeeImage;
        console.log("percent11 : ", percent);
      }
      if (this.employeelist.resume != "") {
        percent += Resume;
        console.log("percent12 : ", percent);
      }
      if (this.employeelist.countryId != 0) {
        percent += CountryId;
        console.log("percent13 : ", percent);
      }
      if (this.employeelist.code != 0) {
        percent += code;
        console.log("percent14 : ", percent);
      }
      if (this.employeelist.mobileNumber != "") {
        percent += mobileNumber;
        console.log("percent15 : ", percent);
      }
      if (this.employeelist.domicile != "") {
        percent += Domicile;
        console.log("percent16 : ", percent);
      }
      if (this.employeelist.languages != "") {
        percent += Languages;
        console.log("percent17 : ", percent);
      }
      if (this.employeelist.sector != "") {
        percent += Sector;
        console.log("percent18 : ", percent);
      }
      /*if (this.employeelist.yearOfExperience != 0) {
        percent += YearOfExperience;
        console.log("percent19 : ", percent);
      }*/
      /*if (this.employeelist.experienceLevel != "") {
        percent += ExperienceLevel;
        console.log("percent20 : ", percent);
      }*/
      if (this.employeelist.expectedSalary != 0) {
        percent += ExpectedSalary;
        console.log("percent21 : ", percent);
      }
      if (this.employeelist.currentSalary != 0) {
        percent += CurrentSalary;
        console.log("percent22 : ", percent);
      }
      /*if (this.employeelist.jobTypId != 0) {
        percent += JobTypId;
        console.log("percent23 : ", percent);
      }*/
      if (this.employeelist.professionalSummary != "") {
        percent += ProfessionalSummary;
        console.log("percent24 : ", percent);
      }
      if (this.employeelist.linkedInLink != "") {
        percent += LinkedInLink;
        console.log("percent25 : ", percent);
      }
      if (this.employeelist.facebookLink != "") {
        percent += FacebookLink;
        console.log("percent26 : ", percent);
      }
      if (this.employeelist.twitterLink != "") {
        percent += TwitterLink;
        console.log("percent27 : ", percent);
      }
      if (this.employeelist.googlePlusLink != "") {
        percent += GooglePlusLink;
        console.log("percent28 : ", percent);
      }
      if (this.employeelist.graduate != 0) {
        percent += Graduate;
        console.log("percent29 : ", percent);
      }
      if (this.employeelist.momReturn != 0) {
        percent += MomReturn;
        console.log("percent30 : ", percent);
      }
      if (this.employeelist.specialNeedyPerson != 0) {
        percent += SpecialNeedyPerson;
        console.log("percent31 : ", percent);
      }
      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee section : ", this.profileStrength);

      if (this.employeeExperience != null) {
        if (this.employeeExperience[0].empExperienceId != 0) {
          percent += empExperienceId;
          console.log("percent32 : ", percent);
        }
        if (this.employeeExperience[0].projectDescription != null) {
          percent += projectDescription;
          console.log("percent33 : ", percent);
        }
        if (this.employeeExperience[0].position != null) {
          percent += position;
          console.log("percent34 : ", percent);
        }
        if (this.employeeExperience[0].projectStartDateMonthYear != null) {
          percent += projectStartDateMonthYear;
          console.log("percent35 : ", percent);
        }
        if (this.employeeExperience[0].projectEndDateMonthYear != null) {
          percent += projectEndDateMonthYear;
          console.log("percent36 : ", percent);
        }
        if (this.employeeExperience[0].projectTitle != null) {
          percent += projectTitle;
          console.log("percent37 : ", percent);
        }
        if (this.employeeExperience[0].referenceNumber != null) {
          percent += referenceNumber;
          console.log("percent38 : ", percent);
        }
        if (this.employeeExperience[0].referenceEmail != null) {
          percent += referenceEmail;
          console.log("percent39 : ", percent);
        }
        if (this.employeeExperience[0].experienceDescription != null) {
          percent += experienceDescription;
          console.log("percent40 : ", percent);
        }
        if (this.employeeExperience[0].salary != 0) {
          percent += salary;
          console.log("percent41 : ", percent);
        }
        if (this.employeeExperience[0].startDateMonthYear != null) {
          percent += startDateMonthYear;
          console.log("percent42 : ", percent);
        }
        if (this.employeeExperience[0].isProjectOngoing != false || this.employeeExperience[0].isProjectOngoing != true) {
          percent += isProjectOngoing;
          console.log("percent43 : ", percent);
        }
        if (this.employeeExperience[0].isPakistan != false) {
          percent += isPakistan;
          console.log("percent44 : ", percent);
        }
        /* if (this.employeeExperience[0].industryId != 0) {
           percent += industryId;
           console.log("percent45 : ", percent);
         }*/
        if (this.employeeExperience[0].endDateMonthYear != null) {
          percent += endDateMonthYear;
          console.log("percent46 : ", percent);
        }
        if (this.employeeExperience[0].currentlyWorking != false || this.employeeExperience[0].currentlyWorking != true) {
          percent += currentlyWorking;
          console.log("percent47 : ", percent);
        }
        if (this.employeeExperience[0].countryId != 0) {
          percent += countryId;
          console.log("percent48 : ", percent);
        }
        if (this.employeeExperience[0].company != "") {
          percent += company;
          console.log("percent49 : ", percent);
        }
        if (this.employeeExperience[0].cityId != 0) {
          percent += cityId;
          console.log("percent50 : ", percent);
        }
        if (this.employeeExperience[0].jobTitle != "") {
          percent += jobTitle;
          console.log("percent51 : ", percent);
        }
        if (this.employeeExperience[0].teamManage != "") {
          percent += teamManage;
          console.log("percent52 : ", percent);
        }

      }
      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Experience section : ", this.profileStrength);
      if (this.employeeEducation != null) {
        if (this.employeeEducation[0].academicProjectDetail != "") {
          percent += academicProjectDetail;
          console.log("percent53 : ", percent);
        }
        if (this.employeeEducation[0].cgpaPercentage != 0) {
          percent += cgpaPercentage;
          console.log("percent54 : ", percent);
        }
        if (this.employeeEducation[0].countryGNId != 0) {
          percent += countryGNId;
          console.log("percent55 : ", percent);
        }
        if (this.employeeEducation[0].degreeLevelId != 0) {
          percent += degreeLevelId;
          console.log("percent56 : ", percent);
        }
        if (this.employeeEducation[0].degreeTitle != "") {
          percent += degreeTitle;
          console.log("percent57 : ", percent);
        }
        if (this.employeeEducation[0].educationDescription != "") {
          percent += educationDescription;
          console.log("percent58 : ", percent);
        }
        if (this.employeeEducation[0].empEducationId != 0) {
          percent += empEducationId;
          console.log("percent59 : ", percent);
        }
        if (this.employeeEducation[0].endDate != null) {
          percent += endDate;
          console.log("percent60 : ", percent);
        }
        if (this.employeeEducation[0].expectedGraduationYear != null) {
          percent += expectedGraduationYear;
          console.log("percent61 : ", percent);
        }
        if (this.employeeEducation[0].graduationCompletionYear != null) {
          percent += graduationCompletionYear;
          console.log("percent62 : ", percent);
        }
        if (this.employeeEducation[0].institute != "") {
          percent += institute;
          console.log("percent63 : ", percent);
        }
        /*if (this.employeeEducation[0].majorFieldId != 0) {
          percent += majorFieldId;
          console.log("percent64 : ", percent);
        }*/

        if (this.employeeEducation[0].projectDetails != "") {
          percent += projectDetails;
          console.log("percent65 : ", percent);
        }
        if (this.employeeEducation[0].resultType != 0) {
          percent += resultType;
          console.log("percent66 : ", percent);
        }
        if (this.employeeEducation[0].startDate != null) {
          percent += startDate;
          console.log("percent67 : ", percent);
        }
        if (this.employeeEducation[0].stateGNId != 0) {
          percent += stateGNId;
          console.log("percent68 : ", percent);
        }
      }
      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Education section : ", this.profileStrength);

      if (this.employeeSkill != null) {
        if (this.employeeSkill[0].empSkillId != 0) {
          percent += empSkillId;
          console.log("percent69 : ", percent);
        }
        if (this.employeeSkill[0].skillLevelOutof100 != 0) {
          percent += skillLevelOutof100;
          console.log("percent70 : ", percent);
        }
        if (this.employeeSkill[0].skillsSubCategoryId != 0) {
          percent += skillsSubCategoryId;
          console.log("percent71 : ", percent);
        }
      }
      this.profileStrength = Math.ceil(percent);
      console.log("total after Employee Skill section : ", this.profileStrength);

    },

    caculateAge(dob) {
      //var diff_ms = Date.now() - dob.getTime();
      //var age_dt = new Date(diff_ms);

      //return Math.abs(age_dt.getUTCFullYear() - 1970);

      var formatedDate = moment(String(dob)).format('YYYY/MM/DD');
      var today = new Date();
      var birthDate = new Date(formatedDate);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      return age;
    },
    //dropdownsLists
    getMaritalStatusList() {
      this.maritalStatusList = [];
      let model = {
        type: "MaritalStatus"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.maritalStatusList = res.data.data;

          console.log("Add Response portion");

          console.log("maritalStatusList : ", this.maritalStatusList);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },
    getCityList() {
      this.cities = null;
      let model = {
        type: "City"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.cities = res.data.data;

          console.log("Add Response portion");

          console.log("cities : ", this.cities);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },
    getYearOfExperienceList() {
      this.yearsOfExperiences = [];
      let model = {
        type: "YearofExperience"
      };

      axios
        .post("/api/KeyValuePair/GetDropDownListByIds", model)
        .then(res => {
          // code that we will 'try' to run
          this.yearsOfExperiences = res.data.data;

          console.log("Add Response portion");

          console.log("yearsOfExperiences : ", this.yearsOfExperiences);
        })
        .catch(error => {
          console.log("Errorrrrrr : ", error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          } else {






          }
        });
    },

    getFeaturedJobs() {
      this.list = null;
      this.featuredJobs = [];
      //this.latestJobs = [];


      axios.get('/api/Job/GetAlljobsOfEmployeer')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }



          this.list = res.data.data;
          console.log("All Jobs : ", this.list);
          let currentDate = moment(String(new Date())).format('YYYY-MM-DD')
          for (let job in this.list) {
            if (this.list[job].jobType === "FeatureJob" && this.list[job].status === "Approved" && moment(String(this.list[job].jobExpiryDate)).format('YYYY-MM-DD') >= currentDate) {
              this.featuredJobs.push(this.list[job]);
            }

          }

          console.log("Featured Jobs : ", this.featuredJobs);
          /* for (let ljob in this.list){
             if(this.list[ljob].jobType === "LatestJob" && this.list[ljob].status === "Approved"){
               this.latestJobs.push(this.list[ljob]);
             }
           }
           console.log("latestJobs Jobs : ", this.latestJobs);*/
          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },

    getCompanies() {
      this.companies = [];

      axios.get('/api/EmployerCompany/GetCompany')
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }







          let list = res.data.data;

          for (let job in list) {
            if (list[job].companyStatus === true) {
              this.companies.push(list[job]);
            }

          }

          console.log("companies : ", this.companies);

          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {

            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    onFollowUnfollowCompany(companyRecord) {

      console.log("companyRecord", companyRecord);


      if (companyRecord.companyStatus) {
        this.companyStatus = false;
      }
      else {
        this.companyStatus = true;
      }
      let model = {

        employeeCompanyId: Number(companyRecord.employerCompanyId),
        followStatus: this.companyStatus

      };
      axios.post('/api/EmployerCompany/UpdateCompanyStatusByEmployee', model)
        .then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }


          /*forEach((item, index) => {
        const country = {
          id: item.gnId,
          title: item.name,
        };*/
          //this.featuredJobs = res.data.data;
          console.log("follow job Response : ", res.data.data);
          this.getCompanies();
          // });
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          if (this.errored) {
            this.getCompanies();
            console.log("Error : Sorry there is no response");
            // this.$bvToast.toast("Sorry there is no response ", {
            //   toaster: "b-toaster-top-center",
            //   variant: "danger",

            //   solid: true,
            // });
          }
        });
    },
    navigate(id) {
      if (id === 1) {
        this.status = 1;
      }
      else if (id === 2) {
        this.status = 2;
      }
      else if (id === 3) {
        this.status = 3;
      }
      else if (id === 4) {
        this.status = 4;
      }
      else if (id === 5) {
        this.status = 5;
      }
    },
    showPrevious() {
      this.$refs.carousel_feature_jobs.prev();
    },
    showNext() {
      this.$refs.carousel_feature_jobs.next();
    },
    reloadPage() {
      if (localStorage.getItem('reloaded')) {
        // The page was just reloaded. Clear the value from local storage
        // so that it will reload the next time this page is visited.
        localStorage.removeItem('reloaded');
      } else {
        // Set a flag so that we know not to reload the page twice.
        localStorage.setItem('reloaded', '1');
        location.reload();
      }
    },
    //Rerender Component
    rerender() {
      this.componentKey += 1;
    },

    getAdds() {
      this.$store.dispatch("fetchAdds");
    },


  },
  computed: {
    sMWCoursesAdd() {
      console.log("availableSMWCoursesAdd : ", this.$store.getters.availableSMWCoursesAdd)
      return this.$store.getters.availableSMWCoursesAdd
    },
    employeeDashboardCenterAdd() {
      console.log("availableSMWCoursesAdd : ", this.$store.getters.availableEmployeeDashboardCenterAdd)
      return this.$store.getters.availableEmployeeDashboardCenterAdd
    },
  },
  mounted() {
    this.getAdds();
    //this.reloadPage();
    this.getAll();
    this.getDashboardCharts();
    //this.getCategories();
    this.getMaritalStatusList();
    this.getCityList();
    this.getCountryList();
    this.getYearOfExperienceList();
    this.getFeaturedJobs();
    this.getCompanies();
    this.appliedJobsStatus();
    this.getJobApplicationChart();
    this.getJobApplicationViewCount();

  }
}
</script>
<style scoped>
.advertisementimage {
  height: 800px;
  width: 100%;
}

@media screen and (max-width:720px) {
  .advertisementimage {
    height: 319px;
    width: 180px;
  }
}

.action-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  display: block;
  /* Allows wrapping if needed */
  margin-bottom: 1rem;
  text-indent: 37px;
}

.action-btn {
  /* margin-right: 1rem; */
  margin-bottom: 0.5rem 0px;
  border: none;
  text-decoration: none;
  color: #ea216c;
  border-radius: 5px;
  transition: 0.3s;
  padding: 4px 6px;
}


.file-info-container {
  margin-top: 1rem;
}

.file-info {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.file-info .custom-text-color {
  margin-left: 0.5rem;
}

.action-buttons {
  display: flex;
  align-items: center;
}

.action-buttons a {
  margin-right: 0.5rem;
}

.card-body {
  padding: 1rem;
}

/* --------------new code-------------- */
.recommended-jobs-header {
  margin-bottom: 20px;
}

.job-card {
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  margin: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.style-left-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.style-left-img img {
  width: 50px;
  height: 50px;
}

.job-info {
  padding-left: 15px;
}

.custom-job-title {
  font-size: 1rem;
  font-weight: bold;
}

.style-company,
.style-job-salary,
.location {
  font-size: 0.9rem;
  color: #6c757d;
}

.btn-apply-now {
  width: 100%;
  font-size: 0.9rem;
}

.custom-btn-bookmark {
  background-color: #ffffff;
  color: #007bff;
  border: 1px solid #007bff;
  font-size: 0.9rem;
}

/* .row-slider-item {
  width: 50% !important;
} */

.custom-btn-bookmark:hover {
  background-color: #007bff;
  color: #ffffff;
}

.custom-b-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.clear-bottom {
  margin-top: 20px;

}



.d-flex {
  display: flex !important;
}
.btnStyle {
 

  border-radius: 3px;
  
  
}
.docsListStyle{
  display: flex;
    list-style: none;
    min-width: 300px; 
    max-width: 350px;
    justify-content: space-between;
}
/* Default styles for larger screens (desktop) */
/* .row-slider-item {
  width: 50% !important;
} */

.job-card {
  max-width: 100%;
}

/* For tablets and smaller devices */
@media (max-width: 1024px) { /* iPad Air (Portrait mode) */
  .row-slider-item {
    width: 100% !important; /* Show 1 item per row */
  }
  
  .job-card {
    max-width: 100%;
  }
  .custom-profile-card{
  background-color: white;
  color: black;width: auto;
  }
  
}

@media (max-width: 768px) { /* Tablets and large mobile devices (Samsung, Oppo tablets) */
  .row-slider-item {
    width: 100% !important;
  }

  .job-card {
    max-width: 100%;
  }
  .container{
    margin: 1.25rem 0rem;
  }
}

@media (max-width: 600px) { /* Larger mobile devices (Samsung, Oppo) */
  .row-slider-item {
    width: 100% !important;
  }

  .job-card {
    max-width: 100%;
  }
  .container{
    margin: 1.25rem 0rem;
  }
}

@media (max-width: 480px) { /* Smaller mobile screens */
  .row-slider-item {
    width: 100% !important;
  }

  .job-card {
    max-width: 100%;
  }
  .container{
    margin: 1.25rem 0rem;
  }
}
.custom-profile-card {
  display: flex; /* Flexbox to center the content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  width: 100%; /* Full width */
  height: 100vh; /* Full viewport height */
}

@media screen and (max-width: 1200px) {
  .custom-profile-card {
    height: auto; /* Adjust height for smaller screens */
  }
}

@media screen and (max-width: 768px) {
  .custom-profile-card {
    flex-direction: column; /* Stack content for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .custom-profile-card {
    padding: 20px; /* Add padding for very small screens */
  }
}




</style>