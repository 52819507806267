<template>
  <!-- prettier-ignore -->
  
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import {mask} from 'vue-the-mask'
// import { ArrowLeftIcon } from "vue-feather-icons";
Vue.use(VueAxios, axios);

//import vSelect from "vue-select";
// import { ModelListSelect } from 'vue-search-select'

// import 'vue-search-select/dist/VueSearchSelect.css'



export default {
  name: "LabourManPower",
  components: {
    // ArrowLeftIcon,
    // ModelListSelect,
    //vSelect
  },
  directives: {mask},
  data() {
    return {
      apiURL: "",
      currentPage: 1,
      total: 1,

      list: [],
      PageOfItems: [],
      info: [],
      option: 0,
      //bool

      twitter_input:true,
      google_input:true,
      facebook_input:true,
      linkedin_input:true,
      professionalSummary_input:true,
      category_dd:true,
      sector_input:true,
      expectedsalary_input:true,
      currentsalary_input:true,
      experiencelevel_input:true,
      experience_input:true,
      language_input:true,
      phone_input:true,
      domicile_input:true,
      area_input:true,
      city_dd:true,
      city_input:false,
      country_dd:true,
      address_input1:true,
      address_input2:true,
      maritalstatus_dd:true,
      gender_dd:true,
      dob_dd:true,
      cnic_input:true,
      fathername_input:true,
      fname_input:true,

      //User Info
      // fullName:null,
      // fatherName:null,
      // cnic:null,
      //dateOfBirth:new Date(),
      selectedGenderType:-1,
      genderType:[
        { value: "-1", text: "Select Gender" },
        { value: "1", text: "Male" },
        { value: "2", text: "Female" },
        { value: "3", text: "Other" },
      ],
      selectedMaritalStatus:-1,
      maritalStatus:[
        { value: "-1", text: "Select Marital Status" },
        { value: "1", text: "Single" },
        { value: "2", text: "Married" },
        { value: "3", text: "Other" },
      ],
      // address:null,
      selectedCountry:null,
      countries:null,
      selectedCity:null,
      cities:null,
      /*area:null,
      domicile:null,
      phoneNo:null,
      language:null,
      experience:null,
      experiencelevel:null,
      currentsalary:null,
      expectedsalary:null,
      sector:null,*/
      selectedCategory:-1,
      categories:null,
      /*personalSummary:null,
      linkedIn:null,
      faceBook:null,
      googleplus:null,
      twitter:null,*/
      cityInput:"",
      emp:{
        fullName:"",
        fatherName:"",
        cnic:"",
        dateofBirth:new Date(),
        gender:0,
        maritalStatus:0,
        address:"",
        area:"",
        cityId:0,
        countryId:0,
        phoneNumber:"",
        domicile:"",
        languages:"",
        sector:"",
        yearOfExperience:0,
        experienceLevel:"",
        currentSalary:0,
        expectedSalary:0,
        jobTypId:0,
        professionalSummary:"",
        linkedInLink:"",
        facebookLink:"",
        googlePlusLink:"",
        twitterLink:""
      },





      //form
      show: true,

      loading: true,
      errored: false,

      rowError: false,
      rowid: 0,

      totalCount: 0,
      componentKey: 0,




      //searchable dropdown
      options:[],

    };
  },

  //shows record first time with default values
  mounted() {
    this.getCategories();
    // this.getCountries();


  },

  methods: {
    getCategories() {
      this.categories = null;
      axios.get('/api/JobType/GetAll')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }


            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.categories = res.data.data;
            console.log("categories : ", this.categories);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },

    getCountries() {
      this.countries = null;
      axios.get('/api/GeoNames/GetAllCountries')
          .then(res =>{
            if (res.status != '200') {
              console.log('error.....')
              const error = new Error('Failed to fetch!');
              return error;
            }

            res.data
            /*forEach((item, index) => {
          const country = {
            id: item.gnId,
            title: item.name,
          };*/
            this.countries = res.data.data;
            console.log("countries : ", this.countries);
            // });
          }).catch(error => {
        console.log(error);
        this.errored = true;
      })
          .finally(() => {
            if (this.errored) {

              console.log("Error : Sorry there is no response");
              // this.$bvToast.toast("Sorry there is no response ", {
              //   toaster: "b-toaster-top-center",
              //   variant: "danger",

              //   solid: true,
              // });
            }
          });

    },
    getCity(country) {
      this.cities = null;
      console.log("selected Country : ",country);
      if (country === 106){
        this.city_input = false;
        this.city_dd = true;
        axios.get('/api/GeoNames/GetCountryCities/').then(res => {
          if (res.status != '200') {
            console.log('error.....')
            const error = new Error('Failed to fetch!');
            return error;
          }

          this.cities = res.data.data;
          console.log("cities : ",this.cities)
        }).catch(error => {
          console.log(error);
          this.errored = true;
        })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              }
            });
      }
      else{
        this.city_input = true;
        this.city_dd = false;
      }


    },
    updateProfile(){
      if (
          this.selectedCategory != -1 &&
          this.selectedCity != null &&
          this.selectedCountry != null &&
          this.selectedMaritalStatus != -1 &&
          this.selectedGenderType != -1
      ) {
        this.info = [];

        this.emp.countryId = Number(this.selectedCountry);
        this.emp.cityId = Number(this.selectedCity);
        this.emp.gender = Number(this.selectedGenderType);
        this.emp.maritalStatus = Number(this.selectedMaritalStatus);
        this.emp.yearOfExperience = Number(this.emp.yearOfExperience);
        this.emp.currentSalary = Number(this.emp.currentSalary);
        this.emp.expectedSalary = Number(this.emp.expectedSalary);

        axios
            .post("/api/Employee/UpdateEmployeePersonalInfo", this.emp)
            .then(res => {
              // code that we will 'try' to run
              this.info = res.data;

              console.log("Add Response portion");

              console.log("response : ", this.info);
            })
            .catch(error => {
              console.log(error);
              this.errored = true;
            })
            .finally(() => {
              if (this.errored) {

                console.log("Error : Sorry there is no response");
                // this.$bvToast.toast("Sorry there is no response ", {
                //   toaster: "b-toaster-top-center",
                //   variant: "danger",

                //   solid: true,
                // });
              } else {
                this.$bvToast.toast("Updated Successfully ", {
                  toaster: "b-toaster-top-center",
                  variant: "success",

                  solid: true
                });
                if(this.selectedCategory==1){
                  this.$router.push({name:'Graduate'});
                }
                else if(this.selectedCategory==2){
                  this.$router.push({name:'MumsReturntoWork'});
                }
                else if(this.selectedCategory==3){
                  this.$router.push({name:'SpecialneedPerson'});
                }
                else if(this.selectedCategory==4){
                  this.$router.push({name:'LabourManPower'});
                }

              }
            });
      } else {
        this.$bvToast.toast("Please Fill all required fields", {
          toaster: "b-toaster-top-center",
          variant: "danger",
          solid: true
        });
      }
    },
    //Binding Dropdown
    // onBindDropdown() {
    //   //this.getUserList();
    //   /*this.getManagerList();*/
    //   //this.getClientList();
    // },
    //User DropDown List
    // getUserList() {
    //   this.loading = true;
    //   this.usersDropDown = [];
    //
    //   axios
    //       .get("/User/GetAll")
    //       .then(res => {
    //         console.log("response : " , res.data.status);
    //         if (res.data.status == 1) {
    //           //INSERT ONLY EMPLOYEES NOT CUSTOMERS
    //           /* for (let i = 0; i < res.data.data; i++) {
    //             if(res.data.data[i].role === 201){
    //               continue;
    //             }else{
    //               this.usersDropDown.push(res.data.data[i]) ;
    //               console.log("users are: ");
    //               console.log(res.data.data[i]);
    //             }
    //           }*/
    //
    //           this.usersDropDown = res.data.data;
    //
    //           console.log("drpdn : " + this.usersDropDown);
    //         } else if (res.data.Status == 0) {
    //           this.$bvToast.toast("Sorry there is no response", {
    //             toaster: "b-toaster-top-center",
    //             variant: "danger",
    //             title: "Error",
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    // //Manager DropDown List
    // getManagerList() {
    //   this.loading = true;
    //
    //
    //   console.log("Entered in managerid");
    //   console.log("Role is",this.roleselected)
    //
    //   //PM
    //   if(this.roleselected === "100" || this.roleselected === "104" || this.roleselected === "-1"){
    //     this.employeesBasedOnRoleSelected = 0;
    //     this.selectedManager = 0;
    //     this.managerDropDown = [];
    //     this.mgr_dd=false;
    //   }else if(this.roleselected === "105"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 104;
    //   }else if(this.roleselected === "106"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 105;
    //   }else if(this.roleselected === "103" || this.roleselected === "107" || this.roleselected === "108"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 106;
    //   }else if(this.roleselected === "101"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 103;
    //   }else if(this.roleselected === "102"){
    //     this.mgr_dd=true;
    //     this.employeesBasedOnRoleSelected = 101;
    //   }
    //
    //   axios
    //       .get("/Employee/Get/"+this.employeesBasedOnRoleSelected)
    //       .then(res => {
    //         //   , {
    //         //   params:{
    //         //     role:this.employeesBasedOnRoleSelected
    //         //   }
    //         // }
    //         console.log("response : " + res.data.status);
    //         if (res.data.status == 1) {
    //           this.managerDropDown = res.data.data;
    //
    //           console.log("drpdn : " , this.managerDropDown);
    //         } else if (res.data.Status == 0) {
    //           console.log("Sorry there is no response");
    //           // this.$bvToast.toast("Sorry there is no response", {
    //           //   toaster: "b-toaster-top-center",
    //           //   variant: "danger",
    //           //   title: "Error",
    //           //   solid: true
    //           // });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    // getManagerId(){
    //   //GET MANAGER ID
    //   this.getManagerList();
    //   //ALSO GET DYNAMIC DROPDOWN
    //   this.getDynamicDropdownOnRoleBasis();
    // },
    // getDynamicDropdownOnRoleBasis(){
    //   if(this.roleselected === "105"){
    //     //GET PROJECTS
    //     axios.get("/Project/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }
    //   else if(this.roleselected === "106"){
    //     //GET REGIONS
    //     axios.get("/Region/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.Status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   } else if(this.roleselected === "103" || this.roleselected === "107" || this.roleselected === "108"){
    //     axios.get("/Area/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }else if(this.roleselected === "101"){
    //     axios.get("/SubArea/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //
    //       } else if (res.data.status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }else if(this.roleselected === "102"){
    //     axios.get("/Site/GetAll").then((res) => {
    //       console.log("response : "+res.data.status);
    //       if (res.data.status == 1) {
    //         this.dynamicValuesOnRolesDropdown = res.data.data;
    //         console.log(this.list);
    //       } else if (res.data.Status == 0) {
    //
    //         this.$bvToast.toast("Sorry there is no response", {
    //           toaster: "b-toaster-top-center",
    //           variant: "danger",
    //           title:"Error",
    //           solid: true,
    //         });
    //       }
    //
    //     })
    //   }
    // },
    // //Client DropDown List
    // getClientList() {
    //   this.loading = true;
    //   this.clientDropDown = [];
    //
    //   axios
    //       .get("/User/GetAll")
    //       .then(res => {
    //         console.log("response : " + res.data.status);
    //         if (res.data.status == 1) {
    //           this.clientDropDown = res.data.data;
    //
    //           console.log("drpdn : " + this.clientDropDown);
    //         } else if (res.data.Status == 0) {
    //           this.$bvToast.toast("Sorry there is no response", {
    //             toaster: "b-toaster-top-center",
    //             variant: "danger",
    //             title: "Error",
    //             solid: true
    //           });
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error);
    //         this.errored = true;
    //       })
    //       .finally(() => {
    //         this.loading = false;
    //       });
    // },
    //
    //
    //
    //
    // //GET ROLES
    // getRoles(role) {
    //   if (role === 100) {
    //     return "Admin";
    //   } else if (role === 101) {
    //     return "Cluster Owner";
    //   } else if (role === 102) {
    //     return "Site Supervisor";
    //   } else if (role === 103) {
    //     return "Assistant Manager";
    //   } else if (role === 104) {
    //     return "HOD";
    //   } else if (role === 105) {
    //     return "PM";
    //   } else if (role === 106) {
    //     return "RM";
    //   } else if (role === 107) {
    //     return "Grid Lead";
    //   } else if (role === 108) {
    //     return "Team Lead";
    //   }
    // },
    // //GET DEPARTMENTS
    // getDepartment(dep) {
    //   if (dep === 100) {
    //     return "Administration";
    //   } else if (dep === 101) {
    //     return "OSU";
    //   } else if (dep === 102) {
    //     return "Finance";
    //   } else if (dep === 103) {
    //     return "Extra Work";
    //   } else if (dep === 104) {
    //     return "Warehouse";
    //   } else if (dep === 105) {
    //     return "OM";
    //   } else if (dep === 106) {
    //     return "EHS";
    //   }
    // },
    //
    // //Create
    // onCreate() {
    //   if (
    //       this.employeeCode != "" &&
    //       this.fullName != "" &&
    //       this.emailAddress != "" &&
    //       this.password != "" &&
    //       this.roleselected != "-1" &&
    //       this.selectedDepartment != "-1" &&
    //       this.selectedEmployeeType != "-1"
    //   ) {
    //     if (this.password == this.confirmPassword) {
    //       if (
    //           this.password.match(this.pass_validtion) &&
    //           this.confirmPassword.match(this.pass_validtion)
    //       ) {
    //         let model = {
    //           employee:{
    //             employeeId: 0,
    //             employeeCode: this.employeeCode,
    //             department: Number(this.selectedDepartment),
    //             employeeType: this.selectedEmployeeType,
    //             managerId: this.selectedManager,
    //             isDeleted: false,
    //             isActive: true,
    //             userRefId: 0,
    //             user: {
    //               userId: 0,
    //               fullName: this.fullName,
    //               email: this.emailAddress,
    //               emailConfirmed:true,
    //               phoneNumber: this.phoneNo,
    //               lockoutEndDateUtc: "2021-05-03T03:42:00.754Z",
    //               phoneNumberConfirmed:true,
    //               twoFactorEnabled: true,
    //               passwordHash: this.confirmPassword,
    //               accessFailedCount: 0,
    //               securityStamp: "string",
    //               lockoutEnabled: true,
    //               accessEnabled: true,
    //               role: Number(this.roleselected)
    //             },
    //             //clientRefId: this.selectedClient,
    //           }
    //
    //         }
    //
    //         if(this.selectedClient!=0){
    //           model.employee.clientRefId=this.selectedClient;
    //         }
    //
    //         if(this.roleselected==='105'){
    //           model.accessId=this.dynamicItem.projectId;
    //         }else if(this.roleselected==='106'){
    //           model.accessId=this.dynamicItem.regionId;
    //         }else if(this.roleselected === '103' || this.roleselected === '107' || this.roleselected === '108'){
    //           model.accessId=this.dynamicItem.areaId;
    //         }else if(this.roleselected === '101'){
    //           model.accessId=this.dynamicItem.subAreaId;
    //         }else if(this.roleselected==='102'){
    //           model.accessId=this.dynamicItem.siteId;
    //         }
    //
    //         //if(model.employeeType)
    //         console.log("dynamicItem : ", this.dynamicItem);
    //         axios
    //             .post("/Employee/Add", model)
    //             .then(res => {
    //               console.log(res.data);
    //
    //               // $("#addorEditModal").modal("hide");
    //               this.$router.push({ name: "Employees" });
    //             })
    //             .catch(error => {
    //               console.log(error);
    //               this.errored = true;
    //             })
    //             .finally(() => {
    //               if (this.errored) {
    //                 this.$bvToast.toast("Employee Added Successfully ", {
    //                   toaster: "b-toaster-top-center",
    //                   variant: "success",
    //
    //                   solid: true
    //                 });
    //                 console.log("Sorry there is no response");
    //                 //   this.getAll();
    //                 // this.$bvToast.toast("Sorry there is no response ", {
    //                 //   toaster: "b-toaster-top-center",
    //                 //   variant: "danger",
    //
    //                 //   solid: true
    //                 // });
    //               } else {
    //                 console.log("Employee Added Successfully");
    //                 //this.getAll();
    //                 this.$bvToast.toast("Employee Added Successfully ", {
    //                   toaster: "b-toaster-top-center",
    //                   variant: "success",
    //
    //                   solid: true
    //                 });
    //               }
    //             });
    //       } else {
    //         this.$bvToast.toast(
    //             "password must be between 7 to 16 characters which contain only characters, numeric digits, underscore and first character must be a letter",
    //             {
    //               toaster: "b-toaster-top-center",
    //               variant: "danger",
    //
    //               solid: true
    //             }
    //         );
    //       }
    //     } else {
    //       this.$bvToast.toast("Passwords did not match", {
    //         toaster: "b-toaster-top-center",
    //         variant: "danger",
    //
    //         solid: true
    //       });
    //     }
    //   } else {
    //     this.$bvToast.toast("Please Fill all required fields", {
    //       toaster: "b-toaster-top-center",
    //       variant: "danger",
    //
    //       solid: true
    //     });
    //   }
    // },
    // //Reset
    onResetModal() {
      this.emp = {
        fullName:"",
        fatherName:"",
        cnic:"",
        dateofBirth:new Date(),
        gender:0,
        maritalStatus:0,
        address:"",
        area:"",
        cityId:0,
        countryId:0,
        phoneNumber:"",
        domicile:"",
        languages:"",
        sector:"",
        yearOfExperience:0,
        experienceLevel:"",
        currentSalary:0,
        expectedSalary:0,
        jobTypId:0,
        professionalSummary:"",
        linkedInLink:"",
        facebookLink:"",
        googlePlusLink:"",
        twitterLink:""
      };

      // Trick to reset/clear native browser form validation state
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      });
    },
    //
    //
    //
    // //searchable dropdown
    // getData(){
    //   axios.get("/Site/GetAll").then((res) => {
    //     console.log("response : "+res.data.status);
    //     if (res.data.status == 1) {
    //       this.options = res.data.data;
    //       console.log(this.list);
    //     } else if (res.data.Status == 0) {
    //
    //       this.$bvToast.toast("Sorry there is no response", {
    //         toaster: "b-toaster-top-center",
    //         variant: "danger",
    //         title:"Error",
    //         solid: true,
    //       });
    //     }
    //
    //   })
    // },
    // codeAndNameAndDesc (item) {
    //   return `${item.siteId} - ${item.siteName}`
    // },
    // reset1 () {
    //   this.dynamicItem = {}
    // },
    // selectFromParentComponent1 () {
    //   // select option from parent component
    //   this.dynamicItem = this.options[0]
    // },
  }
};
</script>

<style scoped>
.custom-back-btn {
  padding: 0 1.5rem;
  font-size: 1.2rem;
  /* color: white;
  background-color: #212529; */
  border-radius: 0;
}
/* .custom-back-btn:hover {

  color: white;
  background-color: #212529;

} */
.custom-owner-name {
  margin: 10px 20px;
}
.custom-nav-link {
  padding: 0 0.5rem !important;
}

.custom-nav-tabs .custom-nav-link {
  height: 100%;
  padding: 10px !important;
  /* margin: 0px 10px; */
  border: 2px solid transparent;
  border-top: none;
  border-left: none;
  border-right: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}



.custom-nav-tabs .custom-nav-link:hover {
  color: black;
}

.custom-nav-tabs .custom-nav-link.active {
  font-weight: 700;
  font-size: 15px;
  color: #000;
  background-color: #fff;
  border-color: #EA216C;
}
.custom-table td,
.custom-table th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
  width: 50%;
}
.custom-table-stock td,
.custom-table-stock th {
  padding: 0.35rem 0.75rem !important;
  vertical-align: middle;
}
svg {
  color: white;
}
</style>

