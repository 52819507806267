
// import Vue from "vue"
// import GAuth from 'vue-google-oauth2'

// const gauthOption = {
//   // clientId: '687431962450-ekkj198uohfda5ai3qugv6gkgjfhn8hu.apps.googleusercontent.com',
//   // clientId:'143333230435-mta2h47dlten9cj21r24b5ae2r2b6v26.apps.googleusercontent.com',
//   clientId:'143333230435-mta2h47dlten9cj21r24b5ae2r2b6v26.apps.googleusercontent.com',

//   scope: 'profile email',
//   prompt: 'consent',
//   fetch_basic_profile: true
// }

import Vue from "vue";
import GAuth from "vue-google-oauth2";

const gauthOption = {
  // Replace with your actual Google OAuth2 client ID
   // clientId: '687431962450-ekkj198uohfda5ai3qugv6gkgjfhn8hu.apps.googleusercontent.com',
  // clientId:'143333230435-mta2h47dlten9cj21r24b5ae2r2b6v26.apps.googleusercontent.com',
  clientId: "143333230435-mta2h47dlten9cj21r24b5ae2r2b6v26.apps.googleusercontent.com",
  scope: "profile email", // Requested scopes
  prompt: "consent", // Force consent prompt
  fetch_basic_profile: true, // Fetch basic profile information
};

Vue.use(GAuth, gauthOption);